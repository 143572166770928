<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content">
      <div class="modal-header">{{ title }}</div>
      <p class="modal-message">{{ message }}</p>
      <div class="button-container">
        <q-btn @click="onConfirm" color="primary" class="action-btn">
          Yes, specify another PICO for this country
        </q-btn>
        <q-btn @click="onAlternative" color="primary" class="action-btn">
          Yes, specify another PICO for another country
        </q-btn>
        <q-btn @click="onCancel" color="primary" class="action-btn cancel-btn">
          No, I am done specifying PICOs
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'

export default {
  props: {
    isVisible: Boolean,
    title: String,
    message: String,
  },
  methods: {
    onConfirm() {
      EventBus.emit('start-new-pico')
    },
    onAlternative() {
      EventBus.emit('new-pico-for-another-country')
    },
    onCancel() {
      EventBus.emit('cancel-pico')
    },
  },
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%; /* Set width to 90% for responsiveness */
  max-width: 600px; /* Limit the maximum width */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  text-align: center; /* Center-align the content */
}

.modal-header {
  font-size: 1.25em; /* Slightly larger font size */
  font-weight: bold;
  margin-bottom: 15px;
}

.modal-message {
  font-size: 1rem;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Add space between buttons */
}

.action-btn {
  font-size: 0.9rem;
  font-weight: bold;
  padding: 10px 15px; /* Add padding for better click area */
  border-radius: 5px;
  text-transform: none; /* Prevent uppercase transformation */
}

.cancel-btn {
  background: #f5f5f5; /* Use a light background for the cancel button */
  color: #333; /* Darker text for contrast */
}
</style>
