<template>
  <div class="popup">
    <div class="popup-content">
      <div class="centered-content">
        <h5 style="margin-top: 0px">{{ popupTitle }}</h5>
        <img
          :src="getTrafficLightImage()"
          alt="Traffic Light Status"
          class="traffic-light"
        />
        <h7 style="margin: 0px">{{ popupMessage }}</h7>
      </div>
      <!-- Category List with Checkmark or Cross -->
      <ul class="category-list">
        <li
          v-for="category in allCategories"
          :key="category"
          :class="{
            exists: existingCategories.includes(category),
            missing: !existingCategories.includes(category),
          }"
        >
          <span class="icon">
            {{ existingCategories.includes(category) ? '✔' : '✘' }}
          </span>
          {{ category }}
        </li>
      </ul>
      <q-btn
        @click="closePopup"
        class="add-new-btn neumorphic-btn"
        label="Close"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'EvidenceCheckPopup',
  props: {
    popupVisible: {
      type: Boolean,
      required: true,
    },
    outcomes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      existingCategories: [], // Categories present in the backend response
      allCategories: ['Mortality', 'Morbidity', 'HrQoL', 'Safety'], // Complete list of required categories
      popupTitle: '',
      popupMessage: '',
      trafficLightStatus: '',
    }
  },
  async mounted() {
    await this.checkEvidenceSupport()
  },
  methods: {
    async checkEvidenceSupport() {
      try {
        const response = await axios.get('jca/getEvidenceSupport')
        this.processEvidenceResponse(response.data)
      } catch (error) {
        console.error('Error fetching evidence support:', error)
        this.setErrorState()
      }
    },
    processEvidenceResponse(evidenceState) {
      const greenMatch = evidenceState.match(/Green:/)
      const yellowMatch = evidenceState.match(
        /Yellow:.*Missing data on outcomes for: (.+)/,
      )

      if (greenMatch) {
        this.setGreenState()
      } else if (yellowMatch) {
        this.setYellowState(yellowMatch[1])
      } else {
        this.setErrorState()
      }
    },
    setGreenState() {
      this.popupTitle = 'Evidence Support'
      this.popupMessage = 'PICO fully supported by evidence'
      this.existingCategories = [...this.allCategories] // All categories are present
      this.trafficLightStatus = 'green'
    },
    setYellowState(missing) {
      this.popupTitle = 'Evidence Support'
      this.popupMessage = 'PICO partly supported by evidence'
      const missingCategories = missing
        .split(', ')
        .map((category) => category.trim())
      this.existingCategories = this.allCategories.filter(
        (category) => !missingCategories.includes(category),
      )
      this.trafficLightStatus = 'yellow'
    },
    setErrorState() {
      this.popupTitle = 'Evidence Support'
      this.popupMessage = 'Unknown evidence status'
      this.existingCategories = []
    },
    getTrafficLightImage() {
      switch (this.trafficLightStatus) {
        case 'green':
          return require('@/assets/trafficlightGreen.png')
        case 'yellow':
          return require('@/assets/trafficlightYellow.png')
      }
    },
    closePopup() {
      EventBus.emit('close-evidence-popup')
    },
  },
}
</script>

<style scoped>
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.traffic-light {
  width: 48px;
  height: auto;
  margin-bottom: 10px;
}
.category-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}
.category-list li {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 5px 0;
}
.category-list li .icon {
  margin-right: 10px;
  font-weight: bold;
}
.category-list li.exists .icon {
  color: green;
}
.category-list li.missing .icon {
  color: red;
}
</style>
