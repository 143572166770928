<template>
  <div>
    <!-- For the comparator, show an inline spinner if it's still loading -->
    <div class="selected-country-label">
      <div v-if="population" class="selected-country-label">
        <b>Selected Population:</b> {{ population.description }}
      </div>
      <div v-if="intervention" class="selected-country-label">
        <b>Selected Intervention:</b> {{ intervention }}
      </div>

      <span v-if="isComparatorLoading">
        <q-spinner color="primary" size="20px" style="vertical-align: middle" />
        <span>Loading...</span>
      </span>
      <span v-else> <b>Selected Comparator:</b>{{ comparator }} </span>
    </div>
    <h5>Outcome(s)</h5>

    <div>
      <label>Outcome Category:</label>
      <p>{{ selectedCategory }}</p>
    </div>

    <div>
      <label>Definition of Outcome Measure:</label>
      <p>{{ selectedOutcomeMeasure }}</p>
    </div>

    <div>
      <label>Time of Assessment:</label>
      <p>{{ selectedTimeAssessment }}</p>
    </div>

    <div>
      <label>Statistical Measure for Treatment Effect Size:</label>
      <p>{{ selectedStatisticalMeasure }}</p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import {
  fetchConsolidatedOutcomeData,
  fetchOutcomeDetails,
  fetchSelectedOutcomeId,
} from './OutcomeService.ts'

export default {
  name: 'OutcomeForm',
  data() {
    return {
      population: null,
      intervention: null,
      comparator: null,
      selectedOutcomeId: 0,
      selectedCategory: '',
      selectedOutcomeMeasure: '',
      selectedTimeAssessment: '',
      selectedStatisticalMeasure: '',
      isComparatorLoading: true,
    }
  },
  async mounted() {
    // Subscribe to outcome-selected events.
    EventBus.on('outcome-selected', this.handleOutcomeSelected)
    await this.loadConsolidatedData()
    await this.loadSelectedOutcome()
    this.isLoading = false
  },
  activated() {
    if (this.selectedOutcomeId) {
      this.loadOutcomeDetails()
    }
  },
  beforeUnmount() {
    EventBus.off('outcome-selected', this.handleOutcomeSelected)
  },
  methods: {
    async loadConsolidatedData() {
      try {
        const { population, intervention, comparator } =
          await fetchConsolidatedOutcomeData()
        this.population = population
        this.intervention = intervention
        this.comparator = comparator
      } catch (error) {
        console.error('Error fetching consolidated outcome data:', error)
      } finally {
        // Only update the comparator-specific loading flag.
        this.isComparatorLoading = false
      }
    },
    async loadSelectedOutcome() {
      try {
        this.selectedOutcomeId = await fetchSelectedOutcomeId()
        this.loadOutcomeDetails()
      } catch (error) {
        console.error('Error fetching selected outcome id:', error)
      }
    },
    async loadOutcomeDetails() {
      try {
        if (!this.selectedOutcomeId) return
        const details = await fetchOutcomeDetails(this.selectedOutcomeId)
        this.selectedCategory = details.category
        this.selectedOutcomeMeasure = details.outcomeMeasure
        this.selectedTimeAssessment = details.timeAssessment
        this.selectedStatisticalMeasure = details.statisticalMeasurement
      } catch (error) {
        console.error('Error fetching outcome details:', error)
      }
    },
    async handleOutcomeSelected() {
      // Reload outcome ID and details when a new outcome is selected.
      await this.loadSelectedOutcome()
    },
  },
}
</script>

<style scoped>
p {
  margin-top: 5px;
  margin-bottom: 5px;
}
select {
  margin-top: 5px;
}
label {
  margin-right: 10px;
}
.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
</style>
