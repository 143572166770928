<template>
  <div class="pico-form neumorphic-container">
    <!-- Header with support request button -->
    <div class="q-pa-md" style="text-align: right">
      <q-btn
        rounded
        bordered
        style="border-color: #ff7500"
        @click="showSupportDialog = true"
      >
        <img src="@/assets/Short_logo.png" alt="Logo" style="height: 22px" />
        <div class="text-primary">
          Get help in assessing relevant PICOs by conducting
          guideline/literature/HTA Reviews or expert Interviews
        </div>
      </q-btn>
    </div>

    <div>
      <!-- Display selected population and intervention -->
      <div v-if="population" class="selected-country-label">
        <b>Selected Population:</b> {{ population.description }}
      </div>
      <div v-if="intervention" class="selected-country-label">
        <b>Selected Intervention:</b> {{ intervention }}
      </div>
      <!-- PICO Tabs -->
      <q-tabs v-model="activeTab" dense>
        <q-tab name="population" label="Select Population" />
        <q-tab name="intervention" label="Select Intervention" />
        <q-tab name="comparison" label="Select Comparator(s)" />
        <q-tab name="outcome" label="Select Outcome(s)" />
      </q-tabs>

      <!-- PICO Tab Panels -->
      <q-tab-panels v-model="activeTab">
        <q-tab-panel name="population">
          <PopulationForm @navigate="handleTabNavigation" />
        </q-tab-panel>
        <q-tab-panel name="intervention">
          <InterventionForm @navigate="handleTabNavigation" />
        </q-tab-panel>
        <q-tab-panel name="comparison">
          <ComparisonGroupForm @navigate="handleTabNavigation" />
        </q-tab-panel>
        <q-tab-panel name="outcome">
          <OutcomeForm @navigate="handleTabNavigation" />
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <!-- Support Request Dialog -->
    <q-dialog v-model="showSupportDialog">
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Let us support you!</div>
          <p>
            Please briefly describe your request and we will get back to you
            immediately!
          </p>
        </q-card-section>

        <q-card-section>
          <q-input v-model="userName" label="Username" readonly dense filled />
          <q-input
            v-model="supportForm.message"
            label="Message"
            type="textarea"
            class="q-mt-md"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn
            flat
            label="Send Request"
            color="primary"
            @click="sendSupportRequest"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Feedback Dialog -->
    <q-dialog v-model="showFeedbackDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ feedbackMessage }}</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import EventBus from '@/eventBus.js'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import ComparisonGroupForm from './ComparisonGroupForm.vue'
import InterventionForm from './InterventionForm.vue'
import OutcomeForm from './OutcomeForm.vue'
import PopulationForm from './PopulationForm.vue'

// --- PICO RELATED REFS & LOGIC ---
// const id = ref(-1)
const picoId = ref(null)
const activeTab = ref('population')
const population = ref(null)
const intervention = ref(null)

const fetchSelectedPicoId = async () => {
  try {
    const response = await axios.get('jca/selectedPicoId')
    picoId.value = response.data
  } catch (error) {
    console.error('Error fetching selected pico id:', error)
  }
}

const fetchSelectedDatas = async () => {
  await fetchSelectedPicoId()

  try {
    const populationResponse = await axios.get(
      `jca/getPopulationDatasForPicoId/${picoId.value}`,
    )
    population.value = populationResponse.data[0]

    const interventionResponse = await axios.get(
      `jca/getInterventionStringForPicoId/${picoId.value}`,
    )
    intervention.value = interventionResponse.data
  } catch (error) {
    console.error('Error fetching data for tab:', error)
  }
}

const handleTabNavigation = async (tabName) => {
  await fetchSelectedDatas()
  if (tabName === 'basicData') {
    EventBus.emit('asset-tab-navigation', 'navigate-to-basic-data')
  }
  activeTab.value = tabName
}

const showSupportDialog = ref(false)
const showFeedbackDialog = ref(false)
const feedbackMessage = ref('')
const userName = ref('')
const supportForm = ref({
  header: 'Help with Questions',
  message: '',
})

const sendSupportRequest = async () => {
  try {
    const fullMessage = `
      User Name: ${userName.value}
      Message:
      ${supportForm.value.message}
          `
    await axios.post('common/sendMail', {
      ...supportForm.value,
      message: fullMessage,
    })
    feedbackMessage.value =
      'Your support request has been sent. We will get back to you shortly.'
  } catch (error) {
    feedbackMessage.value =
      'There was a problem sending your request. Please try again later'
  } finally {
    showSupportDialog.value = false
    showFeedbackDialog.value = true
  }
}

// --- ON MOUNTed ---
onMounted(async () => {
  // Initialize PICO data
  await fetchSelectedDatas()

  // Listen for tab navigation events
  EventBus.on('asset-tab-navigation', (tabName) => handleTabNavigation(tabName))

  // Support request initialization
  try {
    const currentUserResponse = await axios.get('/jca/getUserNameForUserId')
    userName.value =
      currentUserResponse.data.vorname + ' ' + currentUserResponse.data.name
  } catch (error) {
    console.error('Failed to fetch user email or current question:', error)
  }
})
</script>

<style scoped>
.text-primary {
  color: #ff7500;
}
.selected-country-label {
  margin-top: 1rem;
}
.neumorphic-container {
  /* You can add your neumorphic styles here */
}
</style>
