<template>
  <div class="country-selector-container">
    <div class="country-selector">
      <label for="country-dropdown">Select your outcome:</label>
      <select
        id="country-dropdown"
        v-model="selectedOutcome"
        @change="onOutcomeChange"
        class="neumorphic-select"
      >
        <option value="" disabled>Select an outcome</option>
        <option
          v-for="outcome in outcomes"
          :key="outcome.outcomeId"
          :value="outcome.outcomeId"
        >
          {{ outcome.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="pico-form neumorphic-container">
    <q-tabs v-model="activeTab" dense>
      <q-tab name="outcomes" label="Selected Outcome" />
      <q-tab name="results" label="Document results per outcome" />
    </q-tabs>

    <q-tab-panels v-model="activeTab">
      <q-tab-panel name="outcomes">
        <Outcome />
      </q-tab-panel>

      <q-tab-panel name="results">
        <OutcomeResults />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import axios from 'axios'
import Outcome from './Outcome.vue'
import OutcomeResults from './OutcomeResults.vue'

export default {
  name: 'PICOForm',
  components: { Outcome, OutcomeResults },
  data() {
    return {
      activeTab: 'outcomes', // Default to the outcomes tab
      selectedOutcome: null,
      outcomes: [],
    }
  },
  async mounted() {
    await this.fetchConsolidatedPicoIds()
    await this.fetchOutcomes()
  },
  watch: {
    activeTab(newTab) {
      if (newTab === 'outcomes') {
        this.setOutcomeIfNeeded()
      }
    },
  },
  methods: {
    async onOutcomeChange() {
      if (this.selectedOutcome) {
        await axios.post(
          `/jca/setSelectedOutcomeForConsolidatedPico/${this.selectedOutcome}`,
        )
        EventBus.emit('outcome-selected')
      } else {
        console.warn('No outcome selected.') // Warn if undefined
      }
    },
    async fetchOutcomes() {
      try {
        if (this.consolidatedPicoIds.length > 0) {
          const outcomesResponse = await axios.get(
            `jca/getOutcomesForConsolidatedPicoIds`,
          )
          this.outcomes = outcomesResponse.data // Ensure this is an array of OutcomeDto

          // Pre-select the first outcome if the tab is active
          if (this.activeTab === 'outcomes' && this.outcomes.length > 0) {
            this.setOutcomeIfNeeded()
          }
        } else {
          console.warn('No consolidated PICO IDs available.')
        }
      } catch (error) {
        console.error('Error fetching outcomes:', error)
      }
    },
    async fetchConsolidatedPicoIds() {
      const response = await axios.get('jca/getConsolidatedPicoIds')
      this.consolidatedPicoIds = response.data
    },
    async setOutcomeIfNeeded() {
      // Set the first outcome if none is selected
      if (!this.selectedOutcome && this.outcomes.length > 0) {
        this.selectedOutcome = this.outcomes[0].outcomeId
        await this.onOutcomeChange() // Trigger the outcome change logic
      }
    },
  },
}
</script>

<style scoped>
.country-selector-container {
  margin-bottom: 1rem;
}

.country-selector {
  display: flex;
  align-items: center; /* Vertically align items */
  gap: 0.5rem; /* Space between the label and the dropdown */
}

.neumorphic-select {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: #fff;
  transition: box-shadow 0.2s ease;
}

.neumorphic-select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
</style>
