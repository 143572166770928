<template>
  <transition name="fade">
    <div v-if="isVisible" class="custom-alert-overlay" @click.self="close">
      <div class="custom-alert">
        <div class="custom-alert-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="custom-alert-body">
          <p>{{ message }}</p>
        </div>
        <div class="custom-alert-footer">
          <!-- Using Quasar's q-btn; replace with a plain button if not using Quasar -->
          <q-btn
            color="primary"
            class="add-new-btn neumorphic-btn"
            label="OK"
            @click="close"
          ></q-btn>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomAlert',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Alert',
    },
    message: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Center the alert box */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.custom-alert {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.custom-alert-header,
.custom-alert-body,
.custom-alert-footer {
  margin-bottom: 10px;
}
.custom-alert-header h3 {
  margin: 0;
}
</style>
