// src/services/OutcomeResultsService.js
import axios from 'axios'

// Helper to compare two arrays regardless of order.
function arraysEqual(a, b) {
  if (a.length !== b.length) return false
  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return sortedA.every((val, index) => val === sortedB[index])
}

/**
 * Fetches consolidated outcome information.
 * Returns an object with:
 *   - population: The population data object.
 *   - intervention: The intervention data.
 *   - comparator: The comparator from the matching allResultsForConsolidatedPico entry.
 */
export async function fetchOutcomeData() {
  // Get consolidated picoIds.
  const picoIdsResponse = await axios.get('jca/getConsolidatedPicoIds')
  const picoIds = picoIdsResponse.data

  // Fetch population and intervention data using the first picoId.
  const populationResponse = await axios.get(
    `jca/getPopulationDatasForPicoId/${picoIds[0]}`,
  )
  const population = populationResponse.data[0]

  const interventionResponse = await axios.get(
    `jca/getInterventionStringForPicoId/${picoIds[0]}`,
  )
  const intervention = interventionResponse.data

  // Get the assetId and then all outcome/result data.
  const assetResponse = await axios.get('jca/selectedAssetId')
  const assetId = assetResponse.data

  const allResultsResponse = await axios.get(
    `jca/allResultsForConsolidatedPico/${assetId}`,
  )

  // Find the entry where mergedIds matches picoIds.
  const matchingEntry = allResultsResponse.data.find((entry) =>
    arraysEqual(entry.mergedIds, picoIds),
  )

  return {
    population,
    intervention,
    comparator: matchingEntry ? matchingEntry.combinations : null,
  }
}

/**
 * Fetches the currently selected outcome result.
 */
export async function fetchSelectedOutcomeResult() {
  const response = await axios.get(
    'jca/selectedOutcomeResultForConsolidatedPico',
  )
  return response.data
}

/**
 * Saves outcome results.
 * @param {Object} results - Contains all outcome result fields.
 */
export async function saveOutcomeResults(results) {
  await axios.post('jca/saveOutcomeResults/', results)
}

/**
 * Resets the currently selected outcome result.
 */
export async function resetSelectedOutcomeResult() {
  await axios.post('jca/resetSelectedOutcomeResult')
}
