<template>
  <q-td :class="[getEvidenceTypeClass(content)]" style="padding: 0px">
    <span class="static-cell">{{ content }}</span>
  </q-td>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Number, Object, null],
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    getEvidenceTypeClass(evidenceType) {
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case 'Standard NMA (e.g. Bucher’s adjusted indirect comparison)':
          return 'light-green'
        case 'Population-level adjustment methods: (e.g.: MAIC or STC)':
        case 'Adjustment methods at population level (e.g. MAIC, STC)':
          return 'yellow'
        case 'Naïve/unadjusted comparison':
        case 'Other':
          return 'bg-orange'
        case 'No Evidence/Missing data':
        case 'Missing data':
          return 'red'
        case 'No evidence requested':
          return 'grey'
        default:
          return ''
      }
    },
  },
}
</script>

<style>
.static-cell {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  word-wrap: break-word;
  display: flex;
}
/* Background color classes */
.green {
  color: green;
  padding: 10px;
  border: 1px solid #ccc; /* Optional border */
  margin-top: 5px; /* Space above collapsible content */
}
.light-green {
  border: 1px solid #ccc;
  color: lightgreen;
}
.yellow {
  border: 1px solid #ccc;
  color: #dea900;
}
.orange {
  color: orange;
  border: 1px solid #ccc;
}
.red {
  color: red;
  border: 1px solid #ccc;
}
.grey {
  color: grey;
  border: 1px solid #ccc;
}
</style>
