<template>
  <div>
    <!-- For the comparator, show an inline spinner if it's still loading -->
    <div class="selected-country-label">
      <div v-if="population" class="selected-country-label">
        <b>Selected Population:</b> {{ population.description }}
      </div>
      <div v-if="intervention" class="selected-country-label">
        <b>Selected Intervention:</b> {{ intervention }}
      </div>

      <span v-if="isComparatorLoading">
        <q-spinner color="primary" size="20px" style="vertical-align: middle" />
        <span>Loading...</span>
      </span>
      <span v-else> <b>Selected Comparator:</b> {{ comparator }} </span>
    </div>

    <h5>Results per Outcome</h5>

    <div class="dropdown-container">
      <select
        id="country-dropdown"
        v-model="currentTypeOfEvidence"
        class="neumorphic-select"
      >
        <option value="" disabled>Select a type</option>
        <option v-for="type in typeOfEvidence" :key="type" :value="type">
          {{ type }}
        </option>
      </select>

      <q-btn
        @click="selectOtherResults"
        class="add-new-btn neumorphic-btn"
        label="Select other results for this outcome"
        color="primary"
      />
    </div>

    <div>
      <label for="studyName">Name of Study:</label>
      <input type="text" v-model="studyName" placeholder="Enter study name" />
    </div>

    <div>
      <label for="relativeEffectSize">
        Value for Relative Effect Size (Point Estimate):
      </label>
      <input
        type="text"
        v-model="relativeEffectSize"
        placeholder="Enter point estimate"
      />
    </div>

    <div>
      <label for="pValue">Significance of Point Estimate (p‑value):</label>
      <input
        type="text"
        step="0.001"
        v-model="pValue"
        placeholder="Enter p‑value"
      />
    </div>

    <div>
      <label for="confidenceInterval">
        95% Confidence Interval of the Point Estimate:
      </label>
      <input
        type="text"
        v-model="confidenceInterval"
        placeholder="Enter CI (e.g., 1.5 to 2.5)"
      />
    </div>

    <div>
      <!-- Updated label with examples for absolute value explanation -->
      <label for="treatmentEffectIntervention">
        Absolute Value for Treatment Effect of Our Asset / Intervention:
      </label>
      <input
        type="text"
        v-model="treatmentEffectIntervention"
        placeholder="Enter treatment effect (e.g. median OS of 13 months or 23% affected patients)"
      />
    </div>

    <div>
      <!-- Updated label with examples for absolute value explanation -->
      <label for="treatmentEffectComparator">
        Absolute Value for Treatment Effect of Comparator:
      </label>
      <input
        type="text"
        v-model="treatmentEffectComparator"
        placeholder="Enter treatment effect (e.g., median OS of 6 months or 12% affected patients)"
      />
    </div>

    <!-- Data Finality Section with each option displayed on its own line -->
    <div class="data-finality">
      <label class="data-finality-label">Are the data final or interim?</label>
      <div class="data-finality-options">
        <label class="option">
          <span>Yes, final</span>
          <input
            style="width: 22.5%"
            type="radio"
            v-model="dataFinality"
            :value="true"
          />
        </label>
        <label class="option">
          <span>No, only interim results</span>
          <input
            style="width: 10%"
            type="radio"
            v-model="dataFinality"
            :value="false"
          />
        </label>
      </div>
    </div>

    <q-btn
      @click="showConfirmAddResultDialog"
      class="add-new-btn neumorphic-btn"
      label="Submit Results"
      color="primary"
    />

    <!-- Confirmation Modal to add new result -->
    <ConfirmAddResult
      v-if="isConfirmAddResultOpen"
      @close="closeConfirmAddResult"
      @confirm="handleConfirmAddResult"
    />

    <!-- Dummy component for selecting other results -->
    <SelectOtherResults
      v-if="isDummyComponentOpen"
      @close="closeDummyComponent"
    />

    <!-- GipamAlert for displaying validation alerts -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
    <GipamAlert
      :isVisible="showSubmitAlert"
      :title="submitAlertTitle"
      :message="submitAlertMessage"
      @close="closeSubmitAlert"
    />
  </div>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import EventBus from '@/eventBus.js'
import ConfirmAddResult from './ConfirmAddResult.vue'
import {
  fetchOutcomeData,
  fetchSelectedOutcomeResult,
  resetSelectedOutcomeResult,
  saveOutcomeResults,
} from './OutcomeResultsService.ts'
import SelectOtherResults from './SelectOtherResults.vue'

export default {
  name: 'ResultsForm',
  components: {
    ConfirmAddResult,
    SelectOtherResults,
    GipamAlert,
  },
  data() {
    return {
      resultId: -1,
      studyName: '',
      showSubmitAlert: false,
      population: null,
      intervention: null,
      comparator: null,
      relativeEffectSize: null,
      pValue: null,
      confidenceInterval: '',
      treatmentEffectIntervention: null,
      treatmentEffectComparator: null,
      currentTypeOfEvidence: '',
      dataFinality: true,
      isDummyComponentOpen: false,
      isConfirmAddResultOpen: false,
      // Use a dedicated flag for comparator loading
      isComparatorLoading: true,
      typeOfEvidence: [
        'Randomized controlled trial (RCT)',
        'Subgroup analysis of RCT data',
        'Bayesian network meta-analysis (NMA)',
        'Standard NMA (e.g. Bucher’s adjusted indirect comparison)',
        'Patient-level adjustment to external controls (e.g.: by using propensity scores)',
        'Population-level adjustment methods: (e.g.: MAIC or STC)',
        'Naïve/unadjusted comparison',
        'Other (please specify)',
      ],
      // GipamAlert reactive properties
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      submitAlertTitle: '',
      submitAlertMessage: '',
    }
  },
  methods: {
    async loadOutcomeData() {
      try {
        // Delegate data fetching to the service.
        const { population, intervention, comparator } =
          await fetchOutcomeData()
        this.population = population
        this.intervention = intervention
        // Assume that fetching comparator is the slow part...
        this.comparator = comparator
      } catch (error) {
        console.error('Error fetching outcome data:', error)
      } finally {
        // Only update the comparator-specific loading flag.
        this.isComparatorLoading = false
      }
    },
    async loadSelectedOutcomeResult() {
      try {
        const data = await fetchSelectedOutcomeResult()
        this.resultId = data.id || -1
        this.studyName = data.studyName || ''
        this.relativeEffectSize = data.relativeEffectSize || null
        this.pValue = data.pValue || null
        this.confidenceInterval = data.confidenceInterval || ''
        this.treatmentEffectIntervention =
          data.treatmentEffectIntervention || null
        this.treatmentEffectComparator = data.treatmentEffectComparator || null
        this.currentTypeOfEvidence = data.currentTypeOfEvidence || ''
        this.dataFinality = data.dataFinality === true
      } catch (error) {
        console.error('Error loading selected outcome result:', error)
      }
    },
    async resetInputs() {
      this.resultId = -1
      this.studyName = ''
      this.relativeEffectSize = null
      this.pValue = null
      this.confidenceInterval = ''
      this.treatmentEffectIntervention = null
      this.treatmentEffectComparator = null
      this.currentTypeOfEvidence = ''
      this.dataFinality = false
      await resetSelectedOutcomeResult()
    },
    openDummyComponent() {
      this.isDummyComponentOpen = true
    },
    async closeDummyComponent() {
      this.isDummyComponentOpen = false
      await this.loadSelectedOutcomeResult()
    },
    async showConfirmAddResultDialog() {
      if (!this.validateInputs()) {
        return
      }
      const results = {
        id: this.resultId,
        studyName: this.studyName,
        relativeEffectSize: this.relativeEffectSize,
        pValue: this.pValue,
        confidenceInterval: this.confidenceInterval,
        treatmentEffectIntervention: this.treatmentEffectIntervention,
        treatmentEffectComparator: this.treatmentEffectComparator,
        currentTypeOfEvidence: this.currentTypeOfEvidence,
        dataFinality: this.dataFinality,
      }
      await this.resetInputs()
      try {
        await saveOutcomeResults(results)
      } catch (error) {
        console.error('Error saving results:', error)
      }
      this.triggerSubmitAlert(
        'Submit Successfull',
        'Your result saved successfully',
      )
    },
    async closeConfirmAddResult() {
      EventBus.emit('submit-study-results-clicked')
      await resetSelectedOutcomeResult()
      this.isConfirmAddResultOpen = false
    },
    async handleConfirmAddResult() {
      EventBus.emit('addStudyClicked')
      await resetSelectedOutcomeResult()
      this.isConfirmAddResultOpen = false
    },
    validateInputs() {
      if (
        !this.currentTypeOfEvidence ||
        !this.studyName ||
        this.relativeEffectSize === null
      ) {
        this.triggerAlert(
          'Validation Error',
          'Please fill in the recommended fields: Type of Evidence, Name of Study, and Relative Effect Size.',
        )
        return false
      }
      return true
    },
    selectOtherResults() {
      this.isDummyComponentOpen = true
    },
    async fetchSelectedOutcomeForConsolidatedPico() {
      await this.resetInputs()
      // Any additional logic on outcome selection can be added here.
    },
    // Alert-related methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    triggerSubmitAlert(title, message) {
      this.submitAlertTitle = title
      this.submitAlertMessage = message
      this.showSubmitAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
    closeSubmitAlert() {
      this.showSubmitAlert = false
      this.isConfirmAddResultOpen = true
    },
  },
  async mounted() {
    // For instance, load selected outcome result first, then fetch outcome data.
    await this.loadSelectedOutcomeResult()
    await this.loadOutcomeData()
    EventBus.on(
      'outcome-selected',
      this.fetchSelectedOutcomeForConsolidatedPico,
    )
  },
  beforeUnmount() {
    EventBus.off(
      'outcome-selected',
      this.fetchSelectedOutcomeForConsolidatedPico,
    )
  },
}
</script>

<style scoped>
div {
  margin-bottom: 15px;
}
input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}
label {
  display: block;
  margin-bottom: 5px;
}
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
.dropdown-container {
  display: flex;
  align-items: center;
}
.neumorphic-select {
  flex: 1;
  margin-right: 10px;
}

/* Styles for the Data Finality section */
.data-finality {
  margin-bottom: 15px;
}
.data-finality-label {
  margin-bottom: 5px;
}
.data-finality-options {
  display: flex;
  flex-direction: column; /* each option is on its own line */
  gap: 10px;
}
.data-finality-options .option {
  display: flex; /* change to flex so items are inline */
  align-items: center; /* aligns vertically in the middle */
  gap: 5px; /* space between the text and the input */
  margin-left: 1em;
  font-weight: normal;
}
/* Optionally, ensure the span is inline */
.data-finality-options .option span {
  display: inline;
}

/* Loading overlay (if needed elsewhere) */
.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
</style>
