<template>
  <q-dialog v-model="localDialogVisible" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6">Add New Asset</div>
      </q-card-section>

      <q-card-section>
        <q-input v-model="assetName" label="Asset Name" autofocus dense />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          label="Cancel"
          @click="closeDialog"
          color="primary"
          class="add-new-btn neumorphic-btn"
        />
        <q-btn
          flat
          label="Add Asset"
          @click="addAsset"
          color="primary"
          class="add-new-btn neumorphic-btn"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- GipamAlert Component for displaying validation or error alerts -->
  <GipamAlert
    :isVisible="showAlert"
    :title="alertTitle"
    :message="alertMessage"
    @close="closeAlert"
  />
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'AddAssetDialog',
  props: {
    dialogVisible: Boolean,
  },
  components: {
    GipamAlert,
  },
  data() {
    return {
      assetName: '',
      localDialogVisible: this.dialogVisible,
      // GipamAlert reactive state
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    }
  },
  watch: {
    dialogVisible(val) {
      this.localDialogVisible = val
    },
    localDialogVisible(val) {
      this.$emit('update:dialogVisible', val)
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    closeAlert() {
      this.showAlert = false
    },
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    async addAsset() {
      if (!this.assetName) {
        // Use GipamAlert instead of native alert
        this.triggerAlert('Validation Error', 'Please enter an asset name.')
        return
      }

      try {
        const response = await axios.post(`/jca/addAsset/${this.assetName}`)
        const newAssetId = response.data
        await axios.post(`/jca/setSelectedAsset/${newAssetId}`)

        // Reset the assetName input field
        this.assetName = ''
        this.closeDialog()
        EventBus.emit('asset-selected')
        EventBus.emit('asset-added')
      } catch (error) {
        console.error('Error adding new asset:', error)
        // You might also want to trigger an alert on error if needed:
        this.triggerAlert(
          'Error',
          'There was an error adding the asset. Please try again.',
        )
      }
    },
  },
}
</script>

<style scoped>
/* Add styles specific to the AddAssetDialog */
</style>
