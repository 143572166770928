<template>
  <div>
    <h4>Overall Summary: PICOs & Evidence for the Asset</h4>

    <!-- Loading Spinner Overlay -->
    <div v-if="isLoading" class="loading-overlay">
      <q-spinner color="primary" size="50px" />
    </div>

    <!-- Report type selection using a Quasar dropdown button -->
    <div class="report-selection">
      <q-btn-dropdown color="primary" :label="selectedReportLabel">
        <q-list>
          <q-item clickable v-close-popup @click="setReportType('targeted')">
            <q-item-section>
              <q-item-label>
                Targeted Report: Only countries with documented PICOs included
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="setReportType('extended')">
            <q-item-section>
              <q-item-label>
                Extended Report: All EU member states included
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>

    <PicoEvidenceMatrix @openPopup="openPopup" />
    <ResultsMatrix />
    <EvidenceDetailsPopup
      v-if="isPopupVisible"
      :isVisible="isPopupVisible"
      @close="closePopup"
    />

    <!-- Custom Alert Component -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
  </div>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import axios from 'axios'
import {
  QBtnDropdown,
  QItem,
  QItemLabel,
  QItemSection,
  QList,
  QSpinner,
} from 'quasar'
import EvidenceDetailsPopup from './EvidenceDetailsPopup.vue'
import PicoEvidenceMatrix from './PicoEvidenceMatrix.vue'
import ResultsMatrix from './ResultsMatrix.vue'

export default {
  name: 'PicoSummary',
  components: {
    QBtnDropdown,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QSpinner,
    GipamAlert,
    PicoEvidenceMatrix,
    ResultsMatrix,
    EvidenceDetailsPopup,
  },
  data() {
    return {
      assetId: null,
      isPopupVisible: false,
      // Loading state
      isLoading: false,
      // Reactive properties for the custom alert:
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      // Default selected report type is 'targeted'
      selectedReportType: null,
    }
  },
  computed: {
    // This computed property returns the label text to be shown on the dropdown button
    selectedReportLabel() {
      if (this.selectedReportType === 'extended') {
        return 'Extended Report: All EU member states included'
      } else if (this.selectedReportType === 'targeted') {
        return 'Targeted Report: Only countries with documented PICOs included'
      } else {
        return 'Print Report'
      }
    },
  },
  mounted() {
    this.loadAssetId()
  },
  methods: {
    setReportType(reportType) {
      this.selectedReportType = reportType
      this.printTable()
    },
    openPopup() {
      this.isPopupVisible = true
    },
    closePopup() {
      this.isPopupVisible = false
    },
    async loadAssetId() {
      try {
        const response = await axios.get('jca/selectedAssetId')
        this.assetId = response.data
      } catch (error) {
        console.error('Error loading asset ID:', error)
      }
    },
    async printTable() {
      this.isLoading = true // Start the loading spinner
      try {
        if (this.selectedReportType === 'targeted') {
          await this.printTargetReport()
        } else if (this.selectedReportType === 'extended') {
          await this.printExtendedReport()
        }
      } catch (error) {
        console.error('Error generating report:', error)
        this.triggerAlert(
          'Error',
          'Error generating the report. Please try again later.',
        )
      } finally {
        this.isLoading = false // Stop the loading spinner after completion
      }
    },
    async printTargetReport() {
      const response = await axios.get(
        `/jca/printPdf/${this.assetId}?reportType=targeted`,
        { responseType: 'blob' },
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const currentDate = new Date()
      const dateString = currentDate.toISOString().split('T')[0]
      const assetNameResponse = await axios.get('jca/getSelectedAssetName')
      const assetName = assetNameResponse.data

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(pdfBlob)
      downloadLink.download = `PICO Evidence Report - ${assetName} - ${dateString}.pdf`
      downloadLink.click()
    },
    async printExtendedReport() {
      const response = await axios.get(
        `/jca/printPdf/${this.assetId}?reportType=extended`,
        { responseType: 'blob' },
      )
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const currentDate = new Date()
      const dateString = currentDate.toISOString().split('T')[0]
      const assetNameResponse = await axios.get('jca/getSelectedAssetName')
      const assetName = assetNameResponse.data

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(pdfBlob)
      downloadLink.download = `PICO Evidence Report - ${assetName} - ${dateString}.pdf`
      downloadLink.click()
    },
    // Alert helper methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
  },
}
</script>

<style scoped>
.report-selection {
  display: flex;
  margin-bottom: 1rem;
}

/* Loading spinner overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Light overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
</style>
