<template>
  <q-dialog v-model="localValue">
    <q-card>
      <q-card-section>
        <div class="text-h6">Changes saved successfully.</div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" @click="closeDialog" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    componentName: String,
  },
  emits: ['update:modelValue', 'save-template'],
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    closeDialog() {
      // Using computed property setter will not mutate the prop directly.
      this.localValue = false
      // Also emit the custom event for any additional actions in the parent.
      this.$emit('save-template')
    },
  },
}
</script>
