<template>
  <div class="specify-outcome-form">
    <div class="form-group">
      <label>List of defined outcomes:</label>
      <div class="explanatory-text">
        <p>
          In this final step, you will list the relevant outcome(s) that will be
          used to assess the clinical efficacy, safety, and overall impact of
          the intervention for the specified population. Outcomes of interest
          can include clinical endpoints (e.g., survival, disease progression),
          patient-reported outcomes (e.g., quality of life), and safety measures
          (e.g., adverse events). For each outcome, you must specify:
        </p>
        <ul>
          <li>
            <b>Outcome Measure:</b> The specific endpoint to be assessed (e.g.,
            median progression-free survival, EQ-5D score, frequency of acute
            infections)
          </li>
          <li>
            <b>Time of Assessment: </b> The medical treatment or procedure being
            evaluated
          </li>
          <li>
            <b>Statistical Measurement for Effect Size:</b> Alternative
            treatments or standard care options
          </li>
        </ul>
        <p>
          <b>Important: </b>It’s crucial to define these elements for each
          outcome to ensure clarity and consistency in the assessment. HTA
          authorities and payers often require specific outcome measures and
          statistical methods to demonstrate the clinical and economic value of
          the intervention. Be sure to include all necessary outcomes and detail
          how they will be measured and analyzed for both the intervention and
          comparator(s). Once the outcomes are fully defined for the current
          population, intervention, and comparator(s), you can proceed to define
          additional populations, if needed. Each new PICO will require its own
          set of outcomes.
        </p>
      </div>
      <q-table
        :rows="localOutcomes"
        :columns="columns"
        row-key="id"
        class="q-mt-md"
      >
        <template v-slot:body-cell-select="props">
          <q-td>
            <q-checkbox v-model="props.row.selected" />
          </q-td>
        </template>

        <template v-slot:body-cell-name="props">
          <q-td>{{ props.row.name }}</q-td>
        </template>

        <template v-slot:body-cell-category="props">
          <q-td>{{ props.row.category }}</q-td>
        </template>

        <template v-slot:body-cell-outcomeMeasure="props">
          <q-td>{{ props.row.outcomeMeasure }}</q-td>
        </template>

        <template v-slot:body-cell-timeAssessment="props">
          <q-td>{{ props.row.timeAssessment }}</q-td>
        </template>

        <template v-slot:body-cell-statisticalMeasurement="props">
          <q-td>{{ props.row.statisticalMeasurement }}</q-td>
        </template>
      </q-table>
    </div>

    <div class="form-actions">
      <q-btn
        @click="showOutcomeModal = true"
        color="primary"
        class="add-new-btn neumorphic-btn"
      >
        Add New Outcome
      </q-btn>
      <q-btn
        @click="emitBack"
        color="primary"
        class="add-new-btn neumorphic-btn"
      >
        Back
      </q-btn>
      <q-btn
        @click="finishPico"
        color="primary"
        class="add-new-btn neumorphic-btn"
      >
        Save PICO
      </q-btn>
    </div>

    <OutcomeModal
      v-if="showOutcomeModal"
      :selectedOutcomeOptions="selectedOutcomeOptions"
      @close="showOutcomeModal = false"
      @addOutcome="addOutcome"
    />
    <ConfirmationModal
      :isVisible="showConfirmationModal"
      title="New PICO defined"
      message="Would you finish your current PICO and start a new one?"
    />

    <!-- GipamAlert Component for displaying validation alerts -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />

    <div
      v-if="saveFeedback.message"
      :class="['feedback-message', saveFeedback.type]"
    >
      {{ saveFeedback.message }}
    </div>
  </div>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import EventBus from '@/eventBus.js'
import axios from 'axios'
import ConfirmationModal from './ConfirmationModal.vue'
import OutcomeModal from './OutcomeModal.vue'

export default {
  name: 'OutcomeForm',
  components: {
    OutcomeModal,
    ConfirmationModal,
    GipamAlert,
  },
  data() {
    return {
      assetId: null,
      picoId: null,
      showOutcomeModal: false,
      selectedOutcomeOptions: [],
      localOutcomes: [],
      saveFeedback: {
        message: '',
        type: '', // 'success' or 'error'
      },
      showConfirmationModal: false,
      columns: [
        {
          name: 'select',
          label: 'Select',
          align: 'left',
          field: 'select',
          sortable: false,
          required: true,
        },
        {
          name: 'name',
          label: 'Name',
          align: 'left',
          field: 'name',
          sortable: true,
        },
        {
          name: 'category',
          label: 'Category',
          align: 'left',
          field: 'category',
          sortable: true,
        },
        {
          name: 'outcomeMeasure',
          label: 'Outcome Measure',
          align: 'left',
          field: 'outcomeMeasure',
          sortable: true,
        },
        {
          name: 'timeAssessment',
          label: 'Time of Assessment',
          align: 'left',
          field: 'timeAssessment',
          sortable: true,
        },
        {
          name: 'statisticalMeasurement',
          label: 'Statistical Measurement',
          align: 'left',
          field: 'statisticalMeasurement',
          sortable: true,
        },
      ],
      // GipamAlert reactive state
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    }
  },
  computed: {
    isPicoComplete() {
      return (
        this.currentPopulationId !== null &&
        this.currentInterventionId !== null &&
        this.currentComparisonGroupId !== null &&
        this.currentOutcomeIds !== null
      )
    },
  },
  async mounted() {
    await this.loadAssetId()
    await this.fetchSelectedPicoId()
    const assetOutcomes = await axios.get(`jca/getOutcomeDatas/${this.assetId}`)
    var data = assetOutcomes.data

    this.localOutcomes = data.map((item) => ({
      id: item.id || -1,
      assetId: this.assetId,
      picoId: item.picoId || null,
      name: item.name || '',
      category: this.mapCategoryToDropdownValue(item.category) || '',
      outcomeMeasure: item.outcomeMeasure || '',
      timeAssessment: item.timeAssessment || '',
      statisticalMeasurement: item.statisticalMeasurement || '',
      selected: false, // Default to false initially
    }))

    try {
      const picoOutcomes = await axios.get(
        `jca/getOutcomeDatasForPico/${this.picoId}`,
      )
      const data = picoOutcomes.data
      if (data.length === 0) {
        return
      }

      this.localOutcomes.forEach((outcome) => {
        const matchingData = data.find((item) => item.id === outcome.id)
        if (matchingData) {
          outcome.selected = true
          outcome.picoId = this.picoId
        }
      })
    } catch (error) {
      console.error('Error fetching outcomes:', error)
      this.saveFeedback = {
        message: 'Failed to fetch outcomes. Please try again later.',
        type: 'error',
      }
    }
  },
  methods: {
    emitBack() {
      this.$emit('navigate', 'comparison')
    },

    async finishPico() {
      // Check if at least one outcome is selected
      const isAnyOutcomeSelected = this.localOutcomes.some(
        (outcome) => outcome.selected,
      )

      if (!isAnyOutcomeSelected) {
        this.triggerAlert(
          'Validation Error',
          'Please select at least one outcome before proceeding.',
        )
        return
      }

      // Update picoId based on selection state
      this.localOutcomes.forEach((outcome) => {
        if (!outcome.selected && outcome.picoId === this.picoId) {
          outcome.picoId = null
        } else if (outcome.selected && outcome.picoId !== this.picoId) {
          outcome.picoId = this.picoId
        }
      })

      await this.emitOutcomesData()
      this.showConfirmationModal = true
    },

    async emitOutcomesData() {
      try {
        const response = await axios.post(
          `jca/saveOutcomes/${this.picoId}`,
          this.localOutcomes,
        )
        const insertedIds = response.data
        this.localOutcomes.forEach((outcome, index) => {
          if (insertedIds[index]) {
            outcome.id = insertedIds[index]
          }
        })
      } catch (error) {
        console.error('Error saving outcomes:', error)
      }
    },

    addOutcome(newOutcome) {
      if (newOutcome.statisticalMeasurement === 'other') {
        newOutcome.statisticalMeasurement =
          newOutcome.statisticalMeasurementOther
      }
      newOutcome.assetId = this.assetId
      newOutcome.selected = false
      if (Array.isArray(this.localOutcomes)) {
        this.localOutcomes.push(newOutcome)
      }
      this.showOutcomeModal = false
    },

    mapCategoryToDropdownValue(category) {
      const categoryMapping = {
        mortality: 'Mortality',
        morbidity: 'Morbidity',
        hrqol: 'HrQoL',
        safety: 'Safety',
        other: 'Other',
      }
      return categoryMapping[category.toLowerCase()] || ''
    },

    handleSpecifyAnotherPICO() {
      this.showConfirmationModal = false
      EventBus.emit('new-pico')
    },

    handleSpecifyAnotherPICOForAnotherCountry() {
      this.showConfirmationModal = false
      EventBus.emit('new-pico-for-another-country')
    },

    handleNoSpecifyAnotherPICO() {
      this.showConfirmationModal = false
      this.$emit('navigate', 'basicData')
    },

    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },

    async fetchSelectedPicoId() {
      axios.get('jca/selectedPicoId').then((response) => {
        this.picoId = response.data
      })
    },

    // Alert-related methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
  },
}
</script>

<style scoped>
.specify-outcome-form {
  /* Add your styles */
}

.form-group {
  margin-bottom: 20px;
}

.btn-add {
  margin-right: 10px;
}
</style>
