<template>
  <q-page class="bg-accent">
    <div class="q-pa-md">
      <div class="table-container" :class="{ loading: loading }">
        <GipamTable
          :columns="columns"
          :rows="rows"
          :rowTypes="rowTypes"
          :isMandatory="isMandatory"
          :rowsPerPage="rowsPerPage"
          title="Overview on research activities"
          @option-selected="updateCurrentStatus"
          @link-clicked="handleLinkClicked"
          @update-value="updateValue"
        >
        </GipamTable>
        <div v-if="loading" class="loading-overlay">
          <q-spinner color="primary" size="50px" />
        </div>
      </div>
      <div class="row justify-between">
        <div class="col">
          <q-btn color="primary" class="q-mt-md" @click="goToSelfCheck"
            >Go to Self-Check</q-btn
          >
          <q-btn
            color="primary"
            :class="[
              { 'demo-mode': isDemoMode, 'active-mode': !isDemoMode },
              'q-mt-md',
              'q-ml-md',
            ]"
            :disabled="isDemoMode"
            label="Print the Evidence Generation Plan"
            @click="printExcel"
          />
        </div>
        <q-btn color="primary" class="q-mt-md" @click="save">Save</q-btn>
      </div>
    </div>
    <SaveDialog
      v-model="showSuccessDialog"
      componentName="Success"
      @save-template="closeSuccessDialog"
    />
    <SaveDialog
      v-model="showSaveDialog"
      componentName="Save"
      @save-template="closeSaveDialog"
    />
    <!-- GipamAlert Component for successful deletion (or any other alert) -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
  </q-page>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import SaveDialog from '@/core_controls/GipamSaveDialog/GipamSaveDialogView.vue'
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { fetchData, saveData } from '@/service/MonitoringDataService.ts'
import { columns, options } from '@/utils/ColumnDefinitions.ts'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'egpMonitoring',
  components: {
    SaveDialog,
    GipamTable,
    GipamAlert,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const rows = ref([])
    const egpId = ref(null)
    const rowsPerPage = ref(5)
    const currentStatus = ref(null)
    const showSuccessDialog = ref(false)
    const showSaveDialog = ref(false)
    const rowTypes = ref({}) // reactive map for row types
    const isMandatory = ref({}) // reactive map for mandatory values
    const loading = ref(true)
    const downloading = ref(false)
    const isDemoMode = ref(true)

    // GipamAlert reactive state
    const showAlert = ref(false)
    const alertTitle = ref('')
    const alertMessage = ref('')

    // Call this function to trigger the alert wherever needed (e.g., after deletion)
    const triggerAlert = (title, message) => {
      alertTitle.value = title
      alertMessage.value = message
      showAlert.value = true
    }

    const closeAlert = () => {
      showAlert.value = false
    }

    onMounted(async () => {
      try {
        isDemoMode.value = process.env.VUE_APP_IS_DEMO_MODE === 'true'
        egpId.value = route.query.egpId

        if (egpId.value) {
          const fetchedData = await fetchData(egpId.value)
          rows.value = fetchedData.filter(
            (row) => Object.keys(row).length !== 0,
          )
        } else {
          console.error('egpId not found in route query parameters.')
        }
        rowTypes.value = getRowTypes() // assign the computed row types
        isMandatory.value = getIsMandatory() // assign the computed mandatory fields
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        loading.value = false
      }
    })

    const getRowTypes = () => {
      const types = {}
      rows.value.forEach((row, rowIndex) => {
        types[rowIndex] = {}
        columns.forEach((col) => {
          if (col.type === 'link') {
            types[rowIndex][col.field] = 'link'
          } else if (col.type === 'editable') {
            types[rowIndex][col.field] = 'editable'
          } else if (col.type === 'dropdown') {
            types[rowIndex][col.field] = 'dropdown'
          } else if (col.type === 'monthYear') {
            types[rowIndex][col.field] = 'monthYear'
          } else {
            types[rowIndex][col.field] = 'static'
          }
        })
      })
      return types
    }

    const getIsMandatory = () => {
      const mandatory = {}
      rows.value.forEach((row, rowIndex) => {
        mandatory[rowIndex] = {}
        columns.forEach((column) => {
          mandatory[rowIndex][column.field] = !!column.isMandatory
        })
      })
      return mandatory
    }

    const updateValue = (value, rowIndex, columnIndex) => {
      const fieldName = columns[columnIndex].field
      rows.value[rowIndex][fieldName] = value
    }

    const handleLinkClicked = (rowIndex) => {
      const egpIdVal = route.query.egpId
      const studyId = rows.value[rowIndex].studyId
      const link = `https://gipamhealth.sharepoint.com/sites/Evigator/Freigegebene%20Dokumente/Forms/AllItems.aspx?FilterField1=EgpId&FilterValue1=${egpIdVal}&FilterField2=StudyId&FilterValue2=${studyId}`
      window.open(link, '_blank')
    }

    const updateCurrentStatus = (value, rowIndex, columnIndex) => {
      const fieldName = columns[columnIndex].field
      rows.value[rowIndex][fieldName] = value
    }

    const groupRowsByTemplateId = () => {
      return rows.value.reduce((result, row) => {
        const { templateId } = row
        if (!result[templateId]) {
          result[templateId] = []
        }
        result[templateId].push(row)
        return result
      }, {})
    }

    const save = async () => {
      const egpIdVal = route.query.egpId
      try {
        await saveData(egpIdVal, rows.value)
        showSuccessDialog.value = true
        // Optionally trigger an alert after successful save
        triggerAlert('Success', 'Data saved successfully!')
      } catch (error) {
        console.error('Error saving data:', error)
        showSaveDialog.value = true
      }
    }

    const goToSelfCheck = async () => {
      router.push(`/SelfCheck?egpId=${egpId.value}`)
    }

    // Updated printExcel function
    const printExcel = async () => {
      const egpIdVal = route.query.egpId
      downloading.value = true
      try {
        const response = await axios.get(`/template/printEgp/${egpIdVal}`, {
          responseType: 'blob',
        })
        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(excelBlob)
        // Generate the file name with just the date (yyyy-mm-dd)
        const currentDate = new Date()
        const dateString = currentDate.toISOString().split('T')[0]
        downloadLink.download = `EgpReport - ${dateString}.xlsx`
        downloadLink.click()
        console.log('Excel file downloaded')
      } catch (error) {
        triggerAlert(
          'Fehler',
          'Fehler beim Herunterladen der Excel-Datei. Bitte versuchen Sie es später erneut.',
        )
      } finally {
        downloading.value = false
      }
    }

    const closeSuccessDialog = () => {
      showSuccessDialog.value = false
    }

    const closeSaveDialog = () => {
      showSaveDialog.value = false
    }

    return {
      columns,
      rows,
      options,
      rowsPerPage,
      currentStatus,
      rowTypes,
      isMandatory,
      updateValue,
      handleLinkClicked,
      updateCurrentStatus,
      groupRowsByTemplateId,
      save,
      goToSelfCheck,
      printExcel,
      showSuccessDialog,
      showSaveDialog,
      closeSuccessDialog,
      closeSaveDialog,
      loading,
      downloading,
      isDemoMode,
      // GipamAlert bindings and methods
      showAlert,
      alertTitle,
      alertMessage,
      closeAlert,
      triggerAlert,
    }
  },
}
</script>

<style scoped>
.table-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.loading {
  opacity: 0.5;
}

.demo-mode {
  background-color: grey;
  cursor: not-allowed;
}

.active-mode {
  color: white;
  background-color: #ff7500;
}
</style>
