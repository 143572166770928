<template>
  <div class="comparison-group-form">
    <div class="form-groups-container">
      <div class="form-group-container">
        <div class="form-group">
          <label>Add new Comparator:</label>
          <p>
            In this step, you will define the comparator(s) for the specified
            population and intervention. The comparators represent current
            standard of care options, including ‘best supportive care’ or ‘no
            treatment’ when no active treatments are available for this specific
            case. You can specify the comparators as monotherapy, combination
            therapy, or in conjunction with permitted background treatments
            (e.g., radiation therapy, best supportive care, etc.).<br />
            <b>Important:</b>
            Multiple comparators can be listed. In some cases, a comparative
            analysis against all specified comparators may be mandatory,
            depending on the local HTA requirements. For example, in certain HTA
            or reimbursement decisions, evidence must be provided for each
            comparator to fully assess the intervention’s relative benefit. In
            other cases, comparing the intervention to just one or some of the
            listed comparators may be sufficient. Ensure that all relevant
            comparators for the current population and intervention are listed
            before proceeding to additional PICOs with alternative populations
            or interventions.
          </p>
          <p></p>
          <label>Comparator Treatments:</label>
          <p class="info-text">
            The comparators entered are defined as equivalent alternatives for
            this specific PICO:
          </p>
          <q-btn
            @click="openComparatorTable(index)"
            color="primary"
            class="add-new-btn neumorphic-btn"
            style="margin-left: 10px"
          >
            Select Comparator from another PICO
          </q-btn>
          <div
            v-for="(comparator, index) in comparators"
            :key="index"
            class="comparator-group"
          >
            <div
              class="comparator-header"
              style="display: flex; align-items: center"
            >
              <label style="margin-right: 10px">
                Comparator {{ index + 1 }}:
              </label>
              <q-btn
                v-if="comparators.length > 1"
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeComparator(index)"
                style="margin-left: auto"
              />
            </div>
            <div class="form-control">
              <input
                type="text"
                v-model="comparator.combinations[0]"
                placeholder="Enter treatment"
                class="input-field"
                required
              />
              <q-btn
                @click="addCombination(index)"
                color="primary"
                class="add-new-btn neumorphic-btn"
                title="Add new combination partner"
              >
                +
              </q-btn>
            </div>
            <div
              v-for="(
                combination, combinationIndex
              ) in comparator.combinations.slice(1)"
              :key="combinationIndex"
              class="combination-treatment"
            >
              <input
                type="text"
                v-model="comparator.combinations[combinationIndex + 1]"
                placeholder="Enter combination treatment"
                class="input-field"
              />
              <q-btn
                flat
                round
                color="red"
                icon="close"
                size="sm"
                @click="removeCombination(index, combinationIndex + 1)"
              />
            </div>
          </div>
          <q-btn
            @click="addComparator"
            color="primary"
            class="add-new-btn neumorphic-btn"
          >
            Add Comparator
          </q-btn>
        </div>

        <div class="form-group">
          <label>Do you need multiple comparators for this population?</label>
          <div class="radio-group">
            <div class="radio-item">
              <input
                type="radio"
                id="single-comparator"
                v-model="multipleComparators"
                :value="false"
              />
              <label for="single-comparator" class="normal-label">
                Only one of the comparators listed above is required for the JCA
              </label>
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="multiple-comparators"
                v-model="multipleComparators"
                :value="true"
              />
              <label for="multiple-comparators" class="normal-label">
                All comparator treatments listed above are required for the JCA
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <q-btn
        label="Back"
        @click="emitBack"
        color="primary"
        class="add-new-btn neumorphic-btn"
      />
      <q-btn
        label="Next"
        @click="emitNext"
        color="primary"
        class="add-new-btn neumorphic-btn"
      />
    </div>

    <!-- Modal dialog for the ComparatorTable -->
    <q-dialog v-model="showTable" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Select a Comparator</div>
        </q-card-section>
        <q-card-section>
          <ComparatorTable
            :comparisionGroupOptions="comparisionGroupOptions"
            :comparisonColumns="comparisonColumns"
            @select-comparator="handleComparatorSelection"
            @close="showTable = false"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- GipamAlert Component for displaying validation alerts -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
  </div>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import axios from 'axios'
import ComparatorTable from './SelectComparator.vue' // Import child component

export default {
  name: 'ComparisonGroupForm',
  components: {
    ComparatorTable,
    GipamAlert,
  },
  data() {
    return {
      assetId: null,
      picoId: null,
      showTable: false,
      selectedComparator: null,
      comparisionGroupOptions: [],
      comparators: [{ combinations: [''] }],
      multipleComparators: null,
      selectedComparatorIndex: 0, // Index of the comparator being edited
      // GipamAlert reactive state
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    }
  },
  async mounted() {
    await this.loadAssetId()
    await this.fetchSelectedPicoId()
    await this.fetchComparisionDatas()
    // Fetch comparison group IDs
    const response = await axios.get(
      `jca/getComparisionGroupForPicoId/${this.picoId}`,
    )
    this.comparisonGroup = response.data[0]
    if (this.comparisonGroup) {
      const comparisonGroup = this.comparisonGroup
      // Initialize the grouped data structure
      const groupedData = {
        id: comparisonGroup.id,
        combinations: {},
        multipleComparators: comparisonGroup.multipleComparators,
      }

      // Process the combinations from the single comparison group
      comparisonGroup.combination.forEach((combo) => {
        const comparatorKey = combo.comparator
        if (!groupedData.combinations[comparatorKey]) {
          groupedData.combinations[comparatorKey] = []
        }
        groupedData.combinations[comparatorKey].push(...combo.combinations)
      })
      // Set the comparators based on grouped data
      this.comparators = Object.keys(groupedData.combinations).map((key) => ({
        combinations: groupedData.combinations[key],
      }))

      this.multipleComparators = this.comparisonGroup.multipleComparators
    }
  },
  computed: {
    selectLabel() {
      return this.comparisionGroupOptions.length === 0
        ? 'No Comparators Available'
        : 'Select Comparator from anoterh PICO'
    },
    comparisonColumns() {
      return [
        {
          name: 'selected',
          label: 'Select',
          align: 'left',
          sortable: false,
        },
        {
          name: 'label',
          label: 'Comparator Group',
          align: 'left',
          field: 'label',
        },
      ]
    },
  },
  methods: {
    openComparatorTable(index) {
      this.resetComparators() // Reset all comparators
      this.selectedComparatorIndex = index // Store index of comparator being edited
      this.showTable = true // Open comparator selection dialog
    },
    resetComparators() {
      // Reset to default state
      this.comparators = [{ combinations: [''] }]
      this.multipleComparators = null
    },
    emitBack() {
      this.$emit('navigate', 'intervention')
    },
    async emitNext() {
      // Validate multipleComparators selection
      if (this.multipleComparators === null) {
        this.triggerAlert(
          'Validation Error',
          'Please select whether you need multiple comparators.',
        )
        return
      }

      // Validate that each comparator field is filled
      for (const comparator of this.comparators) {
        if (!comparator.combinations[0]) {
          this.triggerAlert(
            'Validation Error',
            'Please fill in all required fields for each comparator.',
          )
          return
        }
        for (const combination of comparator.combinations) {
          if (!combination) {
            this.triggerAlert(
              'Validation Error',
              'Please fill in all required fields for each comparator.',
            )
            return
          }
        }
      }

      const comparisonGroupData = {
        id: this.selectedComparator?.id || -1,
        picoId: this.picoId,
        assetId: this.assetId,
        combination: this.comparators,
        multipleComparators: this.multipleComparators,
      }

      try {
        await axios.post('jca/saveComparisonGroup', comparisonGroupData)
        this.$emit('navigate', 'outcome')
      } catch (error) {
        console.error('Error saving comparison group:', error)
      }
    },
    async fetchComparisionDatas() {
      try {
        const response = await axios.get(
          `jca/getComparisionDatas/${this.assetId}`,
        )
        this.backendData = response.data

        const groupedData = this.backendData.reduce((acc, item) => {
          const key = item.id
          if (!acc[key]) {
            acc[key] = {
              id: key,
              combinations: {},
              multipleComparators: item.multipleComparators,
            }
          }

          item.combination.forEach((combo) => {
            const comparatorKey = combo.comparator
            if (!acc[key].combinations[comparatorKey]) {
              acc[key].combinations[comparatorKey] = []
            }
            acc[key].combinations[comparatorKey].push(...combo.combinations)
          })
          return acc
        }, {})

        let slicedGroupedData = {}
        const keys = Object.keys(groupedData)

        // Build a unique list of combinations
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const comparatorKeys = Object.keys(groupedData[key].combinations)

          for (let j = 0; j < comparatorKeys.length; j++) {
            const comparatorKey = comparatorKeys[j]
            const uniqueCombinations = Array.from(
              new Set(groupedData[key].combinations[comparatorKey]),
            )

            if (!slicedGroupedData[key]) {
              slicedGroupedData[key] = { combinations: {} }
            }

            if (!slicedGroupedData[key].combinations[comparatorKey]) {
              slicedGroupedData[key].combinations[comparatorKey] = []
            }

            slicedGroupedData[key].combinations[comparatorKey].push(
              ...uniqueCombinations,
            )
          }
        }

        console.log(slicedGroupedData)
        // Create a unique list for comparisionGroupOptions
        this.comparisionGroupOptions = Object.values(slicedGroupedData)
          .flatMap((group) => {
            return Object.keys(group.combinations).map((comparatorKey) => {
              const combos = group.combinations[comparatorKey].join(' + ')
              return {
                value: group,
                label: combos,
              }
            })
          })
          .filter(
            (option, index, self) =>
              index === self.findIndex((t) => t.label === option.label),
          )
      } catch (error) {
        console.error('Error fetching comparison data:', error)
      }
    },
    async handleComparatorSelection(selectedComparatorArray) {
      const labelsArray = selectedComparatorArray.map(
        (comparator) => comparator.label,
      )

      for (let i = 0; i < labelsArray.length; i++) {
        const combinationsArray = labelsArray[i].split(' + ')
        for (let j = 0; j < combinationsArray.length; j++) {
          if (!this.comparators[i]) {
            this.addComparator()
          }
          this.comparators[i].combinations[j] = combinationsArray[j]
        }
      }
      this.showTable = false
    },
    addComparator() {
      this.comparators.push({ combinations: [''] })
    },
    removeComparator(index) {
      this.comparators.splice(index, 1)
    },
    addCombination(index) {
      this.comparators[index].combinations.push('')
    },
    removeCombination(comparatorIndex, combinationIndex) {
      this.comparators[comparatorIndex].combinations.splice(combinationIndex, 1)
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    async fetchSelectedPicoId() {
      await axios.get('jca/selectedPicoId').then((response) => {
        this.picoId = response.data
      })
    },
    // Alert-related methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
  },
  watch: {
    comparators: {
      handler: 'emit',
      deep: true,
    },
    selectedComparator(newValue) {
      if (newValue) {
        this.$emit('select-comparator', newValue)
      }
    },
  },
}
</script>

<style scoped>
.comparison-group-form {
  /* Add your styles */
}

.form-groups-container {
  display: flex;
  flex-wrap: nowrap;
}

.form-group {
  flex: 1;
  margin-right: 20px;
  min-width: 300px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.comparator-group {
  margin-bottom: 15px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-item {
  margin-bottom: 10px;
}

.normal-label {
  font-weight: normal;
}

.input-field:invalid {
  border: 2px solid #ff7500;
}
</style>
