<template>
  <div class="matrix-container">
    <h5>PICO Evidence Matrix</h5>
    <div v-if="isLoading" class="loading-overlay">
      <q-spinner color="primary" size="50px" />
    </div>
    <GipamTable
      v-else
      :rows="formattedPicoData"
      :columns="columns"
      :row-types="getRowTypes()"
      :is-mandatory="isMandatory"
      table-title="PICO Evidence Summary"
    />
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'PicoEvidenceMatrix',
  components: {
    GipamTable,
  },
  data() {
    return {
      assetId: null,
      selectedResults: [],
      formattedPicoData: [],
      columns: [
        { label: 'Countries', field: 'countries' },
        { label: 'Population', field: 'population' },
        { label: 'Intervention', field: 'intervention' },
        { label: 'Comparator', field: 'combinations' },
        // Set a minimum width for the "Outcome(s)" column:
        {
          label: 'Outcome(s)',
          field: 'outcomes',
        },
        { label: 'Importance', field: 'importance' },
      ],
      isLoading: true, // Add loading state
    }
  },
  computed: {
    isMandatory() {
      return this.formattedPicoData.map(() => ({
        countries: true,
        outcomes: true,
      }))
    },
  },
  async mounted() {
    EventBus.on('split-link-clicked', this.openPopup)
    await this.loadAssetId()

    const allResultsForConsolidatedPico = await axios.get(
      `jca/allResultsForConsolidatedPico/${this.assetId}`,
    )
    this.formattedPicoData = allResultsForConsolidatedPico.data
    this.isLoading = false
  },
  methods: {
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    getRowTypes() {
      const rowTypes = {}
      this.formattedPicoData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}
        this.columns.forEach((col) => {
          rowTypes[rowIndex][col.field] =
            col.field === 'outcomes' ? 'split' : 'static'
        })
      })
      return rowTypes
    },
    async openPopup(evidenceType) {
      const selectedRow = this.formattedPicoData[evidenceType.rowIndex]

      if (selectedRow) {
        this.selectedResults = [{ outcome: evidenceType.evidenceType }]

        try {
          await axios.post('jca/setSelectedMergedIds', selectedRow.mergedIds)
          await axios.post('jca/setSelectedEvidenceType', {
            evidenceType: evidenceType.evidenceType,
          })
          EventBus.emit('addStudyClicked')
        } catch (error) {
          console.error('Error sending selected IDs:', error)
        }
        this.$emit('open-popup')
      }
    },
  },
  beforeUnmount() {
    EventBus.off('split-link-clicked', this.openPopup)
  },
}
</script>

<style scoped>
.matrix-container {
  margin-bottom: 20px;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
</style>
