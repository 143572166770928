<template>
  <q-page>
    <QuestionCard
      :class="{ loading: loading }"
      :key="currentQuestion?.id"
      :question="currentQuestion"
      :componentName="componentName"
      :isLastSelfCheckQuestion="isLastSelfCheckQuestion"
      @next-question="handleNextQuestion"
      @previous-question="handlePreviousQuestion"
      @answer-change="handleAnswerChange"
      @show-template-dialog="showTemplateDialog"
    />
    <div v-if="loading" class="loading-overlay">
      <q-spinner color="primary" size="50px" />
    </div>
    <ErrorDialog
      v-model="errorDialog"
      :message="errorMessage"
      @close-dialog="closeErrorDialog"
    />
    <ErrorDialog
      v-model="templateErrorDialog"
      :message="templateErrorMessage"
      @close-dialog="closeErrorDialog"
    />
    <TemplateDialog
      v-model="templateDialog"
      :componentName="componentName"
      @save-template="handleTemplateSave"
      @cancel-template="handleTemplateCancel"
      persistent
    />
    <FinalTemplateDialog
      v-model="finishTemplateDialog"
      :componentName="componentName"
      @go-to-monitoring="handleNextQuestion"
      @go-to-selfCheck="goToSelfCheck"
      @print-PDF="printPDF"
      @cancel-template="handleTemplateCancel"
      persistent
    />

    <!-- Custom Alert Component for error notifications -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
  </q-page>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamErrorDialogView.vue'
import QuestionCard from '@/core_controls/GipamQuestionCard/GipamQuestionCardView.vue'
import FinalTemplateDialog from '@/core_controls/GipamTemplateDialog/GipamFinalTemplateDialogView.vue'
import TemplateDialog from '@/core_controls/GipamTemplateDialog/GipamTemplateDialogView.vue'
import router from '@/router'
import AnswerService from '@/service/AnswerService.ts'
import QuestionNavigationService from '@/service/QuestionNavigationService.ts'
import QuestionService from '@/service/QuestionService.ts'
import TemplateService from '@/service/TemplateService.ts'
import store from '@/store.js'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'questionnaire-view',
  components: {
    QuestionCard,
    ErrorDialog,
    TemplateDialog,
    FinalTemplateDialog,
    GipamAlert,
  },
  props: {
    egpId: {
      type: String,
    },
    questionnaireQuestionId: {
      type: Number,
    },
    isLastSelfCheckQuestion: {
      type: Boolean,
    },
  },
  data() {
    return {
      errorMessage: '',
      templateErrorMessage:
        'Please add a row to specify input or select "Cancel".',
      emptyTemplateAnswerText: '',
      componentName: '',
      previousAnswerIds: [],
      currentQuestion: { answers: [] }, // Initialize to avoid null
      templateDialog: false,
      templateDatas: null,
      errorDialog: false,
      templateErrorDialog: false,
      finishTemplateDialog: false,
      loading: true,
      router: useRouter(),
      route: useRoute(), // Call useRoute here and store the returned value
      // Reactive properties for the custom alert:
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    }
  },
  computed: {
    selectedAnswerIds() {
      if (this.currentQuestion && this.currentQuestion.answers) {
        return this.currentQuestion.answers
          .filter((answer) => answer.selected)
          .map((answer) => answer.id)
      }
      return [] // Return an empty array if currentQuestion is null
    },
  },
  async mounted() {
    try {
      this.currentQuestion = await QuestionService.getCurrentQuestion(
        store.getters.getCurrentFrageId,
        store.getters.getCurrentEgpId,
      )
    } catch (error) {
      console.error('Error in mounted hook:', error)
    } finally {
      this.loading = false
    }
  },
  watch: {
    currentQuestion: {
      immediate: true,
      deep: true,
      async handler(newVal) {
        if (newVal === null) {
          // Handle the case where currentQuestion is null
          this.componentName = ''
          return
        }
        this.componentName = ''
        try {
          // Check if the "Yes" answer is already selected
          const yesAnswerId = this.currentQuestion.answers.find((answer) =>
            /^yes\r?\n?$/i.test(answer.text),
          )?.id
          const isYesAnswerSelected = this.currentQuestion.answers.some(
            (answer) => answer.isSelected && answer.id === yesAnswerId,
          )
          // Check if a template exists for the question
          let responseTemplate = null
          if (isYesAnswerSelected) {
            responseTemplate = await AnswerService.processAnswerIds([
              yesAnswerId,
            ])
          }

          if (responseTemplate && isYesAnswerSelected) {
            this.componentName = responseTemplate.name
          }
        } catch (error) {
          console.error('Error in watch handler:', error)
        }
      },
    },
  },

  methods: {
    async handleAnswerChange(answerIds) {
      this.previousAnswerIds =
        this.previousAnswerIds.length === 0
          ? this.currentQuestion.answers
              .filter((answer) => answer.isSelected)
              .map((answer) => answer.id)
          : this.previousAnswerIds
      const newlySelectedAnswers = this.getNewlySelectedAnswers(
        answerIds,
        this.previousAnswerIds,
      )
      this.getDeselectedAnswers(answerIds, this.previousAnswerIds)

      // Update the previousAnswerIds with the new answerIds
      this.previousAnswerIds = [...answerIds]

      // Update the selected property of answers in this.currentQuestion.answers
      if (this.currentQuestion && this.currentQuestion.answers) {
        this.currentQuestion.answers.forEach((answer) => {
          answer.isSelected = answerIds.includes(answer.id)
        })
      }

      if (Array.isArray(answerIds) && answerIds.length === 0) {
        return
      }

      if (newlySelectedAnswers.length > 0) {
        const responseTemplate = await AnswerService.processAnswerIds(answerIds)
        if (responseTemplate) {
          this.componentName = responseTemplate.name
          this.templateDialog = true
        }
      }
    },

    getNewlySelectedAnswers(answerIds, previousAnswerIds) {
      // Find the newly selected answers by filtering out the answers already present in previousAnswerIds
      const newlySelectedAnswers = answerIds.filter(
        (id) => !previousAnswerIds.includes(id),
      )
      return newlySelectedAnswers
    },

    getDeselectedAnswers(answerIds, previousAnswerIds) {
      // Find the deselected answers by filtering out the answers present in answerIds from previousAnswerIds
      const deselectedAnswers = previousAnswerIds.filter(
        (id) => !answerIds.includes(id),
      )
      return deselectedAnswers
    },

    async printPDF() {
      const egpId = store.getters.getCurrentEgpId
      try {
        const response = await axios.get(`/template/printEgp/${egpId}`, {
          responseType: 'blob',
        })

        const excelBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(excelBlob)
        // Generate the file name with just the date (yyyy-mm-dd)
        const currentDate = new Date()
        const dateString = currentDate.toISOString().split('T')[0]
        downloadLink.download = `EgpReport - ${dateString}.xlsx`
        downloadLink.click()
      } catch (error) {
        // Replace native alert() with custom alert call
        this.triggerAlert(
          'Fehler beim Herunterladen der Excel-Datei',
          'Bitte versuchen Sie es später erneut.',
        )
      }
    },

    async handleNextQuestion(currentAnswerId) {
      var emptyTemplateExists = false
      const selectedAnswerIds = this.currentQuestion.answers
        .filter((answer) => answer.isSelected)
        .map((answer) => answer.id)

      for (const answerId of selectedAnswerIds) {
        const responseTemplate = await AnswerService.processAnswerIds([
          answerId,
        ])
        if (responseTemplate) {
          this.responseTemplateDatas = await axios.get(
            '/template/getTemplateAntworten/' +
              store.getters.getCurrentEgpId +
              '/' +
              responseTemplate.id,
          )
          if (!this.responseTemplateDatas.data[0]) {
            const answer = this.currentQuestion.answers.find(
              (answer) => answer.id === answerId,
            )
            emptyTemplateExists = true
            if (
              this.currentQuestion.id === 14 ||
              this.currentQuestion.id === 15 ||
              this.currentQuestion.id === 21 ||
              this.currentQuestion.id === 17
            ) {
              this.errorMessage = `Please edit the template for the following answer: "${answer.text}" or deselect the option.`
            } else {
              this.errorMessage = `Please edit the template, or select "No".`
            }
            break
          }
        }
      }
      if (
        !this.finishTemplateDialog &&
        (!currentAnswerId ||
          currentAnswerId.length === 0 ||
          emptyTemplateExists)
      ) {
        console.log('this.currentQuestion: ', this.currentQuestion.id)
        if (this.currentQuestion.id === 14 || this.currentQuestion.id === 15) {
          this.errorMessage = `Please edit a template, or select "None of these, no additional comparative data needed".`
        } else if (this.currentQuestion.id === 17) {
          this.errorMessage = `Please edit the template, or select " None of these".`
        } else if (this.currentQuestion.id === 21) {
          this.errorMessage = `Please edit the template, or select "Previous Question".`
        } else {
          this.errorMessage = `Please edit the template, or select "No".`
        }
        this.errorDialog = true
        return
      }

      AnswerService.saveAnswer(selectedAnswerIds, this.currentQuestion)
      if (!this.finishTemplateDialog && this.currentQuestion.id === 25) {
        this.finishTemplateDialog = true
        return
      }
      const nextQuestionId = await QuestionService.getNextQuestion(
        this.currentQuestion,
        selectedAnswerIds,
      )
      await this.gotoQuestion(nextQuestionId)

      if (this.hasParentNextQuestionMethod()) {
        this.$emit('next')
      }
    },

    async showTemplateDialog(answerId) {
      const responseTemplate = await AnswerService.processAnswerIds([answerId])
      if (responseTemplate) {
        this.componentName = responseTemplate.name
        this.templateDialog = true
      }
    },

    async handlePreviousQuestion() {
      if (this.currentQuestion.id === 1) {
        router.push('/BasicData/')
        return
      }
      const previousQuestion =
        await QuestionNavigationService.getPreviousQuestions(
          this.currentQuestion,
        )
      if (previousQuestion) {
        this.currentQuestion = previousQuestion
      }
    },

    hasParentNextQuestionMethod() {
      return (
        this.$parent &&
        this.$parent.$options.methods &&
        this.$parent.$options.methods.nextQuestion
      )
    },

    async handleTemplateSave(data) {
      this.templateDatas = data
      if (!data || data.length === 0) {
        console.log('No data to save')
        this.templateErrorDialog = true
        return
      }
      this.templateDialog = false
      try {
        await TemplateService.saveTemplateData(data)
      } catch (err) {
        console.error(err)
        return
      }
    },

    async handleTemplateCancel() {
      this.templateDialog = false
      this.finishTemplateDialog = false
    },

    goToSelfCheck() {
      let egpId = store.getters.getCurrentEgpId
      this.router.push(`/SelfCheck?egpId=${egpId}`)
    },

    closeErrorDialog() {
      this.errorDialog = false
      this.templateErrorDialog = false
    },

    async gotoQuestion(frageId) {
      const egpId = store.getters.getCurrentEgpId
      if (frageId > 0) {
        this.currentQuestion = await QuestionService.getCurrentQuestion(
          frageId,
          egpId,
        )
      } else {
        this.$router.push(`/EgpMonitoring?egpId=${egpId}`)
      }
    },

    // Alert helper methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
  },
}
</script>

<style>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.loading {
  opacity: 0.5;
}

.questions {
  width: 75%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  height: auto;
}
</style>
