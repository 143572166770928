<template>
  <div class="country-selector-container">
    <div class="country-selector">
      <label for="country-dropdown">Select a country:</label>
      <select
        id="country-dropdown"
        v-model="currentPicoCountry"
        class="neumorphic-select"
      >
        <option value="" disabled>Select a country</option>
        <option v-for="country in euCountries" :key="country" :value="country">
          {{ country }}
        </option>
      </select>
    </div>

    <!-- Country Selection Dialog (for selecting a different country without changing the drop-down) -->
    <q-dialog v-model="showCountrySelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select Country</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancelPicoSelection"
          />
        </q-card-section>
        <q-card-section>
          <!-- Use tempCountry instead of currentPicoCountry -->
          <q-select
            v-model="tempCountry"
            :options="uniqueCountryOptions"
            option-label="country"
            label="Select a country"
            @update:model-value="handleCountryChange"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- PICO Selection Dialog -->
    <q-dialog v-model="showPICOSelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select PICO</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancelPicoSelection"
          />
        </q-card-section>
        <q-card-section>
          <q-table
            style="max-width: 1800px"
            :rows="picosForSelectedCountry"
            :columns="columns"
            row-key="picoNumber"
            virtual-scroll
            :virtual-scroll-sticky-size-start="48"
          >
            <template v-slot:body-cell="props">
              <q-td :props="props" style="white-space: pre-line">
                <div v-html="props.value"></div>
              </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
              <q-td>
                <q-btn
                  color="primary"
                  class="add-new-btn neumorphic-btn"
                  flat
                  dense
                  icon="edit"
                  @click="selectPICO(props.row)"
                >
                  Select
                </q-btn>
                <!-- Delete Button using GipamConfirm -->
                <q-btn
                  color="negative"
                  class="delete-btn"
                  flat
                  dense
                  icon="delete"
                  @click="prepareDeletePico(props.row)"
                >
                  Delete
                </q-btn>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="form-actions" v-if="showButtons">
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Create New PICO"
        @click="emitCreateNewPICO"
        :disabled="!currentPicoCountry"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Edit Existing PICO"
        @click="editExistingPico"
        :disabled="!currentPicoCountry"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Select PICO from Another Country"
        @click="createNewPicoFromExisting"
        :disabled="!currentPicoCountry"
      />
    </div>

    <!-- GipamConfirm Component for confirmation -->
    <GipamConfirm
      :isVisible="showConfirm"
      :title="confirmTitle"
      :message="confirmMessage"
      confirmText="Yes"
      cancelText="No"
      @confirm="onConfirm"
      @cancel="showConfirm = false"
      @update:isVisible="(value) => (showConfirm = value)"
    />

    <!-- GipamAlert Component for displaying alerts -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
  </div>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import GipamConfirm from '@/core_controls/GipamConfirm/GipamConfirm.vue'
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'CountrySelectorComponent',
  components: {
    GipamConfirm,
    GipamAlert,
  },
  data() {
    return {
      currentPicoCountry: null,
      tempCountry: null, // temporary country selection for "Select PICO from Another Country"
      euCountries: [],
      picos: [],
      showButtons: true,
      assetId: null,
      editPico: false,
      picoId: null,
      showCountrySelector: false,
      showPICOSelector: false,
      uniqueCountries: [],
      maxVisualizationId: null,
      columns: [
        {
          name: 'picoNumber',
          field: 'picoNumber',
          align: 'left',
        },
        { name: 'country', label: 'Country', field: 'country', align: 'left' },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'combinations',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'actions',
          align: 'center',
        },
      ],
      // GipamConfirm related properties:
      showConfirm: false,
      confirmTitle: '',
      confirmMessage: '',
      confirmAction: '',
      pendingDeletePico: null,
      // GipamAlert related properties:
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    }
  },
  watch: {
    async currentPicoCountry() {
      if (this.picoId && this.picoId != -1 && this.currentPicoCountry) {
        await this.setSelectedPicoid(-1)
        this.emitEvent('show-confirmation-modal', null)
        this.picoId = -1
      }
    },
  },
  async mounted() {
    EventBus.on(
      'show-pico-management-actions-buttons',
      this.setButtonVisibility,
    )
    EventBus.on('current-pico-country', this.setCurrentPicoCountry)

    await this.loadEuCountries()
    await this.loadAssetId()
    const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
    this.picos = picoResponse.data
  },
  computed: {
    uniqueCountryOptions() {
      // Create a map to hold one item per country
      const countryMap = new Map()
      this.picos.forEach((pico) => {
        // If the map does not already have this country, add the full pico object
        if (!countryMap.has(pico.country)) {
          countryMap.set(pico.country, pico)
        }
      })
      // Return the distinct values as an array
      return Array.from(countryMap.values())
    },
    picosForSelectedCountry() {
      // When editing, filter by currentPicoCountry.
      // Otherwise, if selecting from another country, assume tempCountry is an object containing a 'country' property.
      if (this.editPico) {
        return this.picos.filter(
          (pico) => pico.country === this.currentPicoCountry,
        )
      } else {
        return this.tempCountry
          ? this.picos.filter(
              (pico) => pico.country === this.tempCountry.country,
            )
          : []
      }
    },
  },
  methods: {
    async loadEuCountries() {
      const response = await axios.get('jca/euCountries')
      this.euCountries = response.data
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    async fetchSelectedPicoId() {
      axios.get('jca/selectedPicoId').then((response) => {
        this.picoId = response.data
      })
    },
    handleCountryChange(selectedOption) {
      // Update tempCountry (which is already an object)
      this.tempCountry = selectedOption
      this.showCountrySelector = false
      this.showPICOSelector = true
    },
    async prepareDeletePico(pico) {
      this.confirmTitle = 'Confirm Deletion'
      this.confirmMessage = 'Are you sure you want to delete the selected PICO?'
      this.confirmAction = 'deletePico'
      this.pendingDeletePico = pico
      this.showConfirm = true
    },
    async deletePicoConfirmed() {
      // Called after the user confirms deletion in the GipamConfirm dialog.
      try {
        // Call the delete API
        await axios.post(`/jca/deletePico/${this.pendingDeletePico.id}`)
        // Reload the PICO list based on whether we are editing or selecting another country.
        await this.getPicosForCountry(
          this.editPico ? this.currentPicoCountry : this.tempCountry.country,
        )
        // Notify the user with a custom alert
        this.triggerAlert('Success', 'PICO deleted successfully.')
      } catch (error) {
        console.error('Error deleting PICO:', error)
        this.triggerAlert('Error', 'An error occurred while deleting the PICO.')
      } finally {
        this.pendingDeletePico = null
      }
    },
    async handleSpecifyAnotherPICOForAntoherCountry() {
      await this.setSelectedPicoid(null)
      // Do not change the main country selection
      this.setButtonVisibility(true)
    },
    async setSelectedPicoid(picoId) {
      await axios.post(`/jca/setSelectedPicoId/${picoId}`)
    },
    emitEvent(event, payload) {
      EventBus.emit(event, payload)
    },
    async editExistingPico() {
      this.editPico = true
      this.showPICOSelector = true
    },
    onDialogClose() {
      this.showCountrySelector = false
    },
    async createNewPicoFromExisting() {
      const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
      this.picos = picoResponse.data
      this.uniqueCountries = [
        ...new Set(this.picos.map((pico) => pico.country)),
      ]
      this.editPico = false
      // Clear any temporary country selection
      this.tempCountry = null
      this.showCountrySelector = true
    },
    cancelPicoSelection() {
      this.showPICOSelector = false
      this.showCountrySelector = false
      this.setButtonVisibility(true)
    },
    setButtonVisibility(showButtons) {
      this.showButtons = showButtons
    },
    setCurrentPicoCountry(currentPicoCountry) {
      this.currentPicoCountry = currentPicoCountry
    },
    async selectPICO(pico) {
      this.showPICOSelector = false
      this.showCountrySelector = false
      this.picoId = pico.id
      if (!this.editPico) {
        await this.copyPICO()
      }
      await this.setSelectedPicoid(this.picoId)
      this.setButtonVisibility(false)
      this.emitEvent('new-pico-selected', null)
    },
    handleTabNavigation(tabName) {
      this.setButtonVisibility(true)
      if (tabName === 'pico-consolidation') {
        this.$emit('navigate-to-basic-data')
      }
      this.activeTab = tabName // Switch to the specified tab
    },
    async emitCreateNewPICO() {
      await this.getMaxVisualizationId()
      await this.savePicoRequest()
      await this.setSelectedPicoid(this.picoId)
      this.setButtonVisibility(false)
      this.emitEvent('new-pico-selected', null)
    },
    async copyPICO() {
      await this.getMaxVisualizationId()
      await this.copyPicoRequest()
    },
    async savePicoRequest() {
      try {
        const response = await axios.post('jca/savePico', {
          assetId: this.assetId,
          country: this.currentPicoCountry,
          visualizationId: this.maxVisualizationId,
        })
        this.picoId = response.data
      } catch (error) {
        console.error(
          'Error creating new PICO:',
          error.response ? error.response.data : error.message,
        )
      }
    },
    async copyPicoRequest() {
      try {
        const response = await axios.post('jca/copyPico', {
          assetId: this.assetId,
          // Use tempCountry when not editing so that currentPicoCountry remains unchanged
          country: this.editPico
            ? this.currentPicoCountry
            : this.tempCountry.country,
          visualizationId: this.maxVisualizationId,
          oldPicoId: this.picoId,
        })
        this.picoId = response.data
        await this.setSelectedPicoid(this.picoId)
      } catch (error) {
        console.error(
          'Error copying PICO:',
          error.response ? error.response.data : error.message,
        )
      }
    },
    async getMaxVisualizationId() {
      await axios.get('jca/getMaxVisualizationId').then((response) => {
        this.maxVisualizationId = response.data + 1
      })
    },
    async getPicosForCountry(country) {
      axios
        .get('jca/fetchPicosForCountry/' + country + '/' + this.assetId)
        .then((response) => {
          this.picos = response.data
        })
    },
    onConfirm() {
      // This method is called when GipamConfirm emits "confirm"
      if (this.confirmAction === 'deletePico') {
        this.deletePicoConfirmed()
      }
      // Reset dialog flag
      this.showConfirm = false
    },
    // Alert-related methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
  },
  beforeUnmount() {
    EventBus.off(
      'show-pico-management-actions-buttons',
      this.setButtonVisibility,
    )
    EventBus.off('current-pico-country', this.setCurrentPicoCountry)
  },
}
</script>

<style scoped>
@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 1200px;
  }
}
</style>
