import BasicData from '@/components/BasicData.vue'
import DocumentPage from '@/components/DocumentPage.vue'
import EditQuestions from '@/components/EditQuestions.vue'
import EditTemplate from '@/components/EditTemplate.vue'
import EgpMonitoring from '@/components/EgpMonitoring/EgpMonitoringView.vue'
import HomePage from '@/components/HomePage.vue'
import JCA from '@/components/JCA/Asset/AssetMasterView.vue'
import NewEgp from '@/components/NewEgp.vue'
import Questionnaire from '@/components/Questionnaire/QuestionnaireView.vue'
import SelfCheckMasterView from '@/components/SelfCheck/SelfCheckMasterView.vue'
import SelfCheckQuestionnaire from '@/components/SelfCheckQuestionnaire.vue'
import UpdateEgp from '@/components/UpdateEgp.vue'
import AppLayout from '@/layout/AppLayout.vue'
import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store.js'

function requireAuth(to, from, next) {
  if (store.state.loggedIn) {
    if (to.meta.roles != null) {
      let userRole = store.getters.getRole
      if (to.meta.roles.includes(userRole)) {
        next()
      } else {
        next({
          name: 'login',
        })
      }
    }
  } else {
    next({
      name: 'login',
    })
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: HomePage,
        },
        {
          path: '/newEgp',
          name: 'newEgp',
          component: NewEgp,
        },
        {
          path: '/basicData',
          name: 'BasicData',
          component: BasicData,
        },
        {
          path: '/updateEgp',
          name: 'updateEgp',
          component: UpdateEgp,
        },
        {
          path: '/jca',
          name: 'jca',
          component: JCA,
        },
        {
          path: '/Questionnaire',
          name: 'Questionnaire',
          component: Questionnaire,
        },
        {
          path: '/EditQuestions',
          name: 'editQuestions',
          component: EditQuestions,
        },
        {
          path: '/EgpMonitoring',
          name: 'egpMonitoring',
          component: EgpMonitoring,
        },
        {
          path: '/SelfCheck',
          name: 'selfCheck',
          component: SelfCheckMasterView,
        },
        {
          path: '/SelfCheckQuestionnaire',
          name: 'selfCheckQuestionnaire',
          component: SelfCheckQuestionnaire,
        },
        {
          path: '/DocumentPage',
          name: 'documentPage',
          component: DocumentPage,
        },
        {
          path: '/EditTemplate',
          name: 'editTemplate',
          component: EditTemplate,
        },
      ],
      meta: {
        layout: AppLayout,
        roles: ['Admin', 'ReadWrite', 'Read'],
      },
      beforeEnter: requireAuth,
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
    },
    /*
    {
      path: '/registration',
      name: 'registration',
      component: () => import('@/views/RegistrationView.vue'),
    },
    */
  ],
})

export default router
