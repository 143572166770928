<template>
  <div>
    <!-- Readonly QInput triggers the dialog -->
    <q-input
      filled
      v-model="internalValue"
      :label="label"
      readonly
      @click="openDatePicker"
    />
    <!-- QDialog + QDate for picking year and month -->
    <q-dialog v-model="showDatePicker" persistent>
      <q-date
        ref="pickerDateRef"
        v-model="pickerDate"
        default-view="Years"
        emit-immediately
        minimal
        mask="YYYY/MM"
        class="myDate"
        @update:model-value="onUpdatePicker"
      />
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'MonthPicker',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    // Optional label for the input field
    label: {
      type: String,
      default: 'Select Month',
    },
  },
  data() {
    return {
      showDatePicker: false,
      pickerDate: '',
      currentView: 'Years',
      internalValue: this.modelValue,
    }
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal
    },
  },
  methods: {
    openDatePicker() {
      // If already chosen, convert "YYYY-MM" to "YYYY/MM" for the picker
      if (this.modelValue) {
        this.pickerDate = this.modelValue.replace('-', '/')
      } else {
        // Otherwise default to today’s year and month
        const now = new Date()
        const year = now.getFullYear()
        const month = String(now.getMonth() + 1).padStart(2, '0')
        this.pickerDate = `${year}/${month}`
      }
      // Always start in the Years view
      this.currentView = 'Years'
      this.showDatePicker = true
    },
    onUpdatePicker(val) {
      if (this.currentView === 'Years') {
        // Switch to Months view after the year is chosen
        this.currentView = 'Months'
        this.$refs.pickerDateRef.setView('Months')
      } else {
        // When the user selects a month, transform the value
        const newVal = val.replace('/', '-')
        this.internalValue = newVal
        this.$emit('update:modelValue', newVal)
        // Reset view for next time and close the dialog
        this.currentView = 'Years'
        this.showDatePicker = false
      }
    },
  },
}
</script>

<style scoped>
/* Hide extra rows (i.e. the days) so that only year and month are shown */
.myDate > .row ~ .row {
  display: none !important;
}
</style>
