<template>
  <div class="neumorphic-dropdown-country-selector">
    <PICOEvidenceLibrary v-if="!showOutcomes" />
    <div v-else>
      <!-- Back button container aligned to the right -->
      <div class="back-button-container">
        <q-btn
          label="Back to Evidance Library"
          color="primary"
          class="add-new-btn neumorphic-btn"
          @click="goBackToEvidenceLibrary"
        />
      </div>
      <OutcomesMasterView />
    </div>
    <StudyStatusCheckPopup v-if="studyStatusCheckPopupVisible" />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import OutcomesMasterView from './OutcomesMasterView.vue'
import PICOEvidenceLibrary from './PICOEvidenceLibrary.vue'
import StudyStatusCheckPopup from './StudyStatusCheckPopup.vue'

export default {
  name: 'EvidenceLibraryMasterView',
  components: {
    OutcomesMasterView,
    PICOEvidenceLibrary,
    StudyStatusCheckPopup,
  },
  data() {
    return {
      showOutcomes: false,
      studyStatusCheckPopupVisible: false,
    }
  },
  mounted() {
    EventBus.on('addStudyClicked', this.addStudyClicked)
    EventBus.on('submit-study-results-clicked', this.submitStudyResultsClicked)
    EventBus.on('close-evidence-popup', this.closeEvidencePopup)
  },
  methods: {
    addStudyClicked() {
      this.showOutcomes = true
      this.studyStatusCheckPopupVisible = false
    },
    submitStudyResultsClicked() {
      this.showOutcomes = false
      this.studyStatusCheckPopupVisible = true
    },
    closeEvidencePopup() {
      this.studyStatusCheckPopupVisible = false
    },
    goBackToEvidenceLibrary() {
      this.showOutcomes = false
    },
  },
  beforeUnmount() {
    EventBus.off('addStudyClicked', this.addStudyClicked)
  },
}
</script>

<style scoped>
.back-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
