<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <q-btn
        icon="close"
        flat
        round
        dense
        v-close-popup
        @click="$emit('close')"
      />
      <h3>Select Other Results</h3>
      <q-table
        style="max-width: 1800px"
        :rows="results"
        :columns="columns"
        row-key="studyName"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell="props">
          <q-td :props="props" style="white-space: pre-line">
            <div>{{ props.value }}</div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              color="primary"
              class="add-new-btn neumorphic-btn"
              flat
              dense
              icon="edit"
              @click="selectOutcome(props.row)"
              label="Select"
            />
            <!-- Upload Documents Button -->
            <q-btn
              color="secondary"
              class="upload-btn"
              flat
              dense
              icon="upload"
              @click="uploadDocuments(props.row)"
              label="Upload Study documents"
            />
            <!-- Delete Button that triggers the custom confirm -->
            <q-btn
              color="negative"
              class="delete-btn"
              flat
              dense
              icon="delete"
              @click="triggerConfirm(props.row)"
              label="Delete"
            />
          </q-td>
        </template>
      </q-table>
    </div>

    <!-- Custom Confirm Component -->
    <GipamConfirm
      :isVisible="showConfirm"
      title="Confirm Deletion"
      message="Are you sure you want to delete this outcome?"
      confirmText="Yes"
      cancelText="No"
      @confirm="deleteOutcome"
      @cancel="showConfirm = false"
      @update:isVisible="(value) => (showConfirm = value)"
    />

    <!-- Custom Alert for notifications -->
    <GipamAlert
      :isVisible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      @close="closeAlert"
    />
  </div>
</template>

<script>
import GipamAlert from '@/core_controls/GipamAlert/GipamAlert.vue'
import GipamConfirm from '@/core_controls/GipamConfirm/GipamConfirm.vue'
import axios from 'axios'

export default {
  name: 'SelectOtherResults',
  components: {
    GipamAlert,
    GipamConfirm,
  },
  data() {
    return {
      // Control for the confirm modal
      showConfirm: false,
      resultToDelete: null,
      // Data for the custom alert:
      showAlert: false,
      alertTitle: 'Success',
      alertMessage: 'Outcome deleted successfully.',
      showPICOSelector: false,
      results: [], // Populated from the backend
      selectedOutcome: null,
      columns: [
        {
          name: 'studyName',
          label: 'Study Name',
          align: 'left',
          field: 'studyName',
        },
        {
          name: 'currentTypeOfEvidence',
          label: 'Type of Evidence',
          align: 'left',
          field: 'currentTypeOfEvidence',
        },
        {
          name: 'relativeEffectSize',
          label: 'Effect Size',
          align: 'right',
          field: 'relativeEffectSize',
        },
        {
          name: 'pValue',
          label: 'P-Value',
          align: 'right',
          field: 'pValue',
        },
        {
          name: 'confidenceInterval',
          label: 'Confidence Interval',
          align: 'left',
          field: 'confidenceInterval',
        },
        {
          name: 'dataFinality',
          label: 'Data Finality',
          align: 'center',
          field: 'dataFinality',
        },
        { name: 'actions', label: 'Actions', align: 'center' },
      ],
    }
  },
  async mounted() {
    await this.fetchOutcomes() // Fetch outcomes when the component mounts
  },
  methods: {
    async fetchOutcomes() {
      try {
        const response = await axios.get('jca/resultsForConsolidatedPico')
        const rawResults = response.data

        // Filter distinct results based on OutcomeId and StudyName
        const uniqueResults = new Map()
        rawResults.forEach((result) => {
          const key = `${result.outcomeId}-${result.studyName}`
          if (!uniqueResults.has(key)) {
            uniqueResults.set(key, result)
          }
        })

        this.results = Array.from(uniqueResults.values())
      } catch (error) {
        console.error('Error fetching outcomes:', error)
      }
    },
    async selectOutcome(result) {
      this.selectedOutcome = result
      this.showPICOSelector = false
      await axios.post(`/jca/SelectedOutcomeResult/${result.id}`).then(() => {
        this.$emit('close')
      })
    },
    // Trigger the confirm to delete an outcome
    triggerConfirm(result) {
      this.resultToDelete = result
      this.showConfirm = true
    },
    // Actual deletion after confirmation
    async deleteOutcome() {
      if (!this.resultToDelete) return
      try {
        await axios.post(`/jca/DeleteResult/${this.resultToDelete.id}`)
        await this.fetchOutcomes()
        // Show success alert after deletion
        this.triggerAlert('Success', 'Outcome deleted successfully.')
      } catch (error) {
        console.error('Error deleting outcome:', error)
        // Optionally, handle error alert here
      }
    },
    async uploadDocuments(outcome) {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'application/pdf,application/msword'
      fileInput.onchange = async (event) => {
        const file = event.target.files[0]
        if (file) {
          try {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('outcomeId', outcome.id)
            await axios.post('/jca/UploadStuey', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            // Use custom alert instead of native alert:
            this.triggerAlert('Success', 'Document uploaded successfully.')
          } catch (error) {
            console.error('Error uploading document:', error)
            this.triggerAlert(
              'Error',
              'An error occurred while uploading the document.',
            )
          }
        }
      }
      fileInput.click()
    },
    cancelPicoSelection() {
      this.showPICOSelector = false
    },
    // Alert-related methods
    triggerAlert(title, message) {
      this.alertTitle = title
      this.alertMessage = message
      this.showAlert = true
    },
    closeAlert() {
      this.showAlert = false
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.outcomes-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.outcomes-table th,
.outcomes-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.outcomes-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.outcomes-table .selected {
  background-color: #e0e0e0; /* Highlight selected row */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
}

.traffic-light {
  width: 24px;
  height: auto;
  cursor: pointer;
}
</style>
