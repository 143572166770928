<template>
  <transition name="fade">
    <div v-if="isVisible" class="custom-confirm-overlay" @click.self="cancel">
      <div class="custom-confirm">
        <div class="custom-confirm-header">
          <h5>{{ title }}</h5>
        </div>
        <div class="custom-confirm-body">
          <p>{{ message }}</p>
        </div>
        <div class="custom-confirm-footer">
          <!-- Cancel Button -->
          <q-btn
            color="negative"
            class="add-new-btn neumorphic-btn"
            :label="cancelText"
            @click="cancel"
          ></q-btn>
          <!-- Confirm Button -->
          <q-btn
            color="primary"
            class="add-new-btn neumorphic-btn"
            :label="confirmText"
            @click="confirm"
          ></q-btn>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomConfirm',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Confirm',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'OK',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      this.close()
    },
    cancel() {
      this.$emit('cancel')
      this.close()
    },
    close() {
      // Emit an event to close the modal; you might also use an .sync prop or v-model.
      this.$emit('update:isVisible', false)
    },
  },
}
</script>

<style scoped>
.custom-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Center the confirm box */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.custom-confirm {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.custom-confirm-header,
.custom-confirm-body,
.custom-confirm-footer {
  margin-bottom: 10px;
}
.custom-confirm-header h3 {
  margin: 0;
}
.custom-confirm-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
