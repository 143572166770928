// src/services/OutcomeService.js
import axios from 'axios'

// Helper function to compare two arrays (ignoring order)
function arraysEqual(a, b) {
  if (a.length !== b.length) return false
  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return sortedA.every((val, index) => val === sortedB[index])
}

/**
 * Fetches consolidated outcome data including population, intervention, and comparator.
 */
export async function fetchConsolidatedOutcomeData() {
  // Get the array of consolidated picoIds.
  const picoIdsResponse = await axios.get('jca/getConsolidatedPicoIds')
  const picoIds = picoIdsResponse.data

  // Fetch population data and intervention data for the first pico id.
  const populationResponse = await axios.get(
    `jca/getPopulationDatasForPicoId/${picoIds[0]}`,
  )
  const population = populationResponse.data[0]

  const interventionResponse = await axios.get(
    `jca/getInterventionStringForPicoId/${picoIds[0]}`,
  )
  const intervention = interventionResponse.data

  // Get the assetId and then the consolidated outcome results.
  const assetResponse = await axios.get('jca/selectedAssetId')
  const assetId = assetResponse.data

  const allResultsResponse = await axios.get(
    `jca/allResultsForConsolidatedPico/${assetId}`,
  )
  const allResults = allResultsResponse.data

  // Identify the matching entry where mergedIds equals picoIds.
  const matchingEntry = allResults.find((entry) =>
    arraysEqual(entry.mergedIds, picoIds),
  )
  const comparator = matchingEntry ? matchingEntry.combinations : null

  return { population, intervention, comparator }
}

/**
 * Fetches the currently selected outcome ID.
 */
export async function fetchSelectedOutcomeId() {
  const response = await axios.get('jca/selectedOutcomeIdForConsolidatedPico')
  return response.data
}

/**
 * Fetches detailed outcome information for the provided outcome ID.
 */
export async function fetchOutcomeDetails(outcomeId) {
  if (!outcomeId) return null
  const response = await axios.get(`jca/outcomeDetails/${outcomeId}`)
  return response.data
}
