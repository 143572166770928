<template>
  <div>
    <div>
      <q-card class="modal-content">
        <q-card-section>
          <div class="text-h6">Add New Population</div>
        </q-card-section>
        <div class="modal-body">
          <div class="population-form">
            <div class="form-group" v-if="isAddingNewPopulation">
              <label>New Population Name:</label>
              <div class="form-control">
                <input
                  type="text"
                  v-model="newPopulationName"
                  placeholder="Enter new population name"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Disease:</label>
              <div class="form-control">
                <input
                  type="text"
                  v-model="population.disease"
                  placeholder="Enter disease"
                />
              </div>
            </div>
            <div class="form-group">
              <label
                >Disease characteristics (e.g. severity, stage of
                progression):</label
              >
              <div class="form-control">
                <input
                  type="text"
                  v-model="population.diseaseCharacteristics"
                  placeholder="Enter disease characteristics"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Socio-demographic restrictions:</label>
              <div class="form-control">
                <select v-model="population.sociodemographicRestrictions">
                  <option value="">None</option>
                  <option value="restrictions">
                    Restrictions, e.g. in relation to age/gender
                  </option>
                </select>
                <input
                  v-if="
                    population.sociodemographicRestrictions === 'restrictions'
                  "
                  type="text"
                  v-model="sociodemographicRestrictionsText"
                  placeholder="Enter socio-demographic restrictions"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Previous treatments/treatment line:</label>
              <div class="form-control">
                <select v-model="population.previousTreatments">
                  <option value="">None</option>
                  <option value="specify">Specify</option>
                </select>
                <input
                  v-if="population.previousTreatments === 'specify'"
                  type="text"
                  v-model="previousTreatmentsText"
                  placeholder="Enter previous treatments/treatment line"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Other criteria:</label>
              <div class="form-control">
                <select v-model="population.otherCriteria">
                  <option value="">None</option>
                  <option value="specify">Specify other criteria</option>
                </select>
                <input
                  v-if="population.otherCriteria === 'specify'"
                  type="text"
                  v-model="otherCriteriaText"
                  placeholder="Enter other criteria"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Final description for the patient population:</label>
              <div class="form-control">
                <div class="description-display">
                  {{ finalDescription }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <q-btn
            @click="showConfirmationDialog"
            color="primary"
            class="add-new-btn neumorphic-btn"
          >
            Confirm
          </q-btn>
          <q-btn
            @click="$emit('close')"
            color="primary"
            class="add-new-btn neumorphic-btn"
          >
            Cancel
          </q-btn>
        </div>
      </q-card>
    </div>

    <!-- GipamConfirm Component for confirmation -->
    <GipamConfirm
      :isVisible="showConfirm"
      :title="confirmTitle"
      :message="confirmMessage"
      confirmText="Yes"
      cancelText="No"
      @confirm="onConfirm"
      @cancel="showConfirm = false"
      @update:isVisible="(value) => (showConfirm = value)"
    />
  </div>
</template>

<script>
import GipamConfirm from '@/core_controls/GipamConfirm/GipamConfirm.vue'

export default {
  components: {
    GipamConfirm,
  },
  data() {
    return {
      isAddingNewPopulation: false,
      selectedPopulation: null,
      newPopulationName: '',
      population: {
        disease: '',
        diseaseCharacteristics: '',
        sociodemographicRestrictions: '',
        previousTreatments: '',
        otherCriteria: '',
        description: '',
      },
      sociodemographicRestrictionsText: '',
      previousTreatmentsText: '',
      otherCriteriaText: '',
      // GipamConfirm-related properties:
      showConfirm: false,
      confirmTitle: '',
      confirmMessage: '',
      // Used to decide what action to take upon confirmation.
      confirmAction: null,
    }
  },
  computed: {
    finalDescription() {
      const descriptionParts = []
      if (this.population.disease) {
        descriptionParts.push(`Patients with ${this.population.disease}`)
      }
      if (this.population.diseaseCharacteristics) {
        descriptionParts.push(`(${this.population.diseaseCharacteristics})`)
      }
      if (
        this.population.sociodemographicRestrictions === 'restrictions' &&
        this.sociodemographicRestrictionsText
      ) {
        descriptionParts.push(this.sociodemographicRestrictionsText)
      }
      if (
        this.population.previousTreatments === 'specify' &&
        this.previousTreatmentsText
      ) {
        descriptionParts.push(
          `Previous treatments: ${this.previousTreatmentsText}`,
        )
      }
      if (
        this.population.otherCriteria === 'specify' &&
        this.otherCriteriaText
      ) {
        descriptionParts.push(this.otherCriteriaText)
      }
      return descriptionParts.join('; ')
    },
  },
  methods: {
    handleModelValueUpdate(value) {
      if (value.value === null) {
        this.isAddingNewPopulation = true
        this.newPopulationName = ''
        this.population = {
          disease: '',
          diseaseCharacteristics: '',
          sociodemographicRestrictions: '',
          previousTreatments: '',
          otherCriteria: '',
          description: '',
        }
        this.sociodemographicRestrictionsText = ''
        this.previousTreatmentsText = ''
        this.otherCriteriaText = ''
      } else {
        this.isAddingNewPopulation = false
        console.log('Selected population:', value.value)
      }
    },
    showConfirmationDialog() {
      const emptyFields = this.validateFields()
      if (emptyFields.length > 0) {
        this.confirmTitle = 'Incomplete Fields'
        this.confirmMessage = `The following fields are empty: ${emptyFields.join(
          ', ',
        )}. If you continue, your input will be deleted. Do you want to proceed?`
        this.confirmAction = 'confirmPopulation'
        this.showConfirm = true
        return
      }
      // If no empty fields, confirm the final description.
      this.confirmTitle = 'Confirm Population Description'
      this.confirmMessage = this.finalDescription
      this.confirmAction = 'confirmPopulation'
      this.showConfirm = true
    },
    validateFields() {
      const emptyFields = []
      if (!this.population.disease) emptyFields.push('Disease')
      if (!this.population.diseaseCharacteristics)
        emptyFields.push('Disease characteristics')
      if (
        this.population.sociodemographicRestrictions === 'restrictions' &&
        !this.sociodemographicRestrictionsText
      ) {
        emptyFields.push('Sociodemographic Restrictions')
      }
      if (
        this.population.previousTreatments === 'specify' &&
        !this.previousTreatmentsText
      ) {
        emptyFields.push('Previous Treatments')
      }
      if (
        this.population.otherCriteria === 'specify' &&
        !this.otherCriteriaText
      ) {
        emptyFields.push('Other Criteria')
      }
      return emptyFields
    },
    onConfirm() {
      // Called when GipamConfirm returns a confirmation.
      if (this.confirmAction === 'confirmPopulation') {
        this.applyPopulation()
      }
      // Reset confirm dialog flag.
      this.showConfirm = false
    },
    applyPopulation() {
      // Set the final description and add additional details.
      this.population.description = this.finalDescription
      if (
        this.population.otherCriteria === 'specify' &&
        this.otherCriteriaText
      ) {
        this.population.otherCriteria = `${this.population.otherCriteria}: ${this.otherCriteriaText}`
      }
      if (
        this.population.previousTreatments === 'specify' &&
        this.previousTreatmentsText
      ) {
        this.population.previousTreatments = `${this.population.previousTreatments}: ${this.previousTreatmentsText}`
      }
      if (
        this.population.sociodemographicRestrictions === 'restrictions' &&
        this.sociodemographicRestrictionsText
      ) {
        this.population.sociodemographicRestrictions = `${this.population.sociodemographicRestrictions}: ${this.sociodemographicRestrictionsText}`
      }
      this.$emit('addPopulation', this.population)
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-content {
  background-color: #fff;
  border-radius: 4px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 16px;
}
.modal-body {
  margin-bottom: 16px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.add-new-btn {
  margin-left: 8px;
}

.form-group {
  margin-bottom: 16px;
}

.form-control {
  display: flex;
  align-items: center;
}

.form-control input,
.form-control textarea,
.form-control select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.description-display {
  white-space: pre-wrap;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
  }
}
</style>
