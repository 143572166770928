<template>
  <q-td class="split-outcome-cell">
    <div v-for="(outcome, index) in outcomes" :key="index" class="outcome-item">
      <div class="outcome-content">
        <StaticCellView :content="outcome.outcome" style="border-right: none" />
        <q-icon
          class="toggle-icon"
          :name="isContentVisible(index) ? 'expand_less' : 'expand_more'"
          @click="toggleContent(index)"
          aria-hidden="true"
        />
      </div>
      <div
        v-if="
          outcome.evidenceTypes &&
          outcome.evidenceTypes.length > 0 &&
          isContentVisible(index)
        "
        class="collapsible-content"
      >
        <div
          v-for="(evidenceType, i) in outcome.evidenceTypes"
          :key="i"
          class="evidence-item"
        >
          <StaticCellView
            :content="evidenceType"
            @click="handleClick(evidenceType)"
          />
        </div>
      </div>
    </div>
  </q-td>
</template>

<script>
import StaticCellView from './GipamStaticCellView.vue'

export default {
  props: {
    outcomes: {
      type: Array,
      required: true,
    },
  },
  components: {
    StaticCellView,
  },
  data() {
    return {
      visibleContent: Array(this.outcomes.length).fill(false), // Initialize as an array of booleans
    }
  },
  methods: {
    handleClick(evidenceType) {
      this.$emit('split-link-clicked', evidenceType) // Emit the event with the evidenceType
    },
    toggleContent(index) {
      // Toggle visibility for the specific outcome
      this.visibleContent[index] = !this.visibleContent[index]
    },
    isContentVisible(index) {
      return this.visibleContent[index]
    },
  },
}
</script>

<style scoped>
.split-outcome-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: none; /* Optional border for dropdown */
}

.outcome-item {
  margin: 2px 0;
  display: flex;
  flex-direction: column; /* Stack outcome and evidence vertically */
  align-items: flex-start; /* Align items to the start */
  position: relative; /* Position relative for dropdown positioning */
  height: 15px; /* Overwrite q-td height*/
  min-width: 10em;
}

.toggle-icon {
  cursor: pointer;
  margin-left: 8px; /* Space between StaticCellView and icon */
}

.collapsible-content {
  position: absolute; /* Position absolute to overlay other content */
  left: 0;
  top: 100%; /* Position it below the row */
  background-color: #f9f9f9; /* Background color */
  width: 100%; /* Full width of the parent */
  z-index: 1000; /* Ensure it appears on top */
  border: 1px solid #ccc; /* Optional border for dropdown */
}

.evidence-item {
  border-bottom: 1px solid #ddd; /* Optional border for evidence items */
  padding: 5px; /* Optional padding */
}

.outcome-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
